import React from 'react';
import logout from "../../../images/log-out.png";
import "./MenuHeader.scss";


// Header menu component
export const MenuHeader = (properties) => {

  const{ title, userSession, logoutService, props} = properties;


  return (
    <div className="menu-header">
        <h1 className="title">{title}</h1>
        <div>
          <h6>Bienvenido {userSession.name} {userSession.lastName} </h6>
          <div className="menu-header-bar" ></div>
          <div className="logout-container" onClick={() => logoutService(props)}>
            <h6>Salir</h6>
            <img src={logout} alt=""/>
          </div>
        </div>
    </div>
  )
}