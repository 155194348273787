import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {NavLink} from "react-router-dom";
import Loader from 'react-loader-spinner'
import moment from 'moment';
import "./Tables.scss";
import empty from 'is-empty';

export const ConsultBondByIdTable = (props) => {

  const {bond} = props;


  if (bond === "") {
    return null;
  }else {
    return (
      <React.Fragment>
      <h1 id="general-data-title" className="title">Datos generales</h1>

      <div className="bond-number-container">
              <FontAwesomeIcon className="check-square" icon='check-square'/>
              <h6>La fianza consultada ha sido validada</h6>
      </div>
   
        <div className="bond-data-table-container">
        <div>
          <div className="bond-data-table-row table-row-40 ">
              <h6>Número de fianza</h6>
            <h6>{bond.number}</h6>
          </div>
          <div className="bond-data-table-row table-row-60">
            <h6>Aseguradora</h6>
            <h6>{bond.insuranceName}</h6>
          </div>
        </div>      
  
        <div>
          <div className="bond-data-table-row table-row-40 ">
            <h6>Cliente</h6>
            <h6>{bond.client}</h6>
          </div>
          <div className="bond-data-table-row table-row-30">
            <h6>Fecha de acto</h6>
            <h6>{bond.actDate ? moment.utc(bond.actDate).format('DD/MM/YYYY'): ""}</h6>
          </div>
          
          {/* Project Start and end Date */}

          {/* <div className="bond-data-table-row table-row-30">
            <h6>Inicio de la fianza</h6>
            <h6>{moment.utc(bond.startDate).format('DD/MM/YYYY')}</h6>
          </div>
          <div className="bond-data-table-row table-row-30">
            <h6>Fin de la fianza</h6>
            <h6>{moment.utc(bond.endDate).format('DD/MM/YYYY')}</h6>
          </div> */}

        </div>      
  
        {/* Project and Bond Ammount */}
        {/* <div>
          <div className="bond-data-table-row table-row-40 ">
            <h6>Monto de la fianza</h6>
            <h6>B/. {parseFloat(bond.bondAmount).toLocaleString(undefined, {maximumFractionDigits:2})}</h6>
          </div>
          <div className="bond-data-table-row table-row-60">
            <h6>Monto del proyecto</h6>
            <h6>B/. {parseFloat(bond.projectAmount).toLocaleString(undefined, {maximumFractionDigits:2})}</h6>
          </div>
        </div>       */}
      </div>


      </React.Fragment>
      )
  }
}


// Users list Table component 
export const UsersTable = (props) => {

  const {users, loadUserDataForEdition} = props;


  if (!users) {
    return null 
  }else {
    return (
      <table id="users-table" className="users-table">
      <thead>
        <tr>
          <th>Tipo de usuario</th>
          <th>Nombre y apellido</th>
          <th>Email</th>
          <th>Organización</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          users.map((user, i) => (
                <tr key={i}>
                    <td ><h6>{user.userTypeName}</h6></td>
                    <td ><h6>{user.name} {user.lastName}</h6></td>
                    <td ><h6>{user.email}</h6></td>
                    <td ><h6>{user.userTypeName === "Gobierno" ? user.govTypeName : (user.userTypeName === "Aseguradora" ? user.insuranceName : user.insuranceName)}</h6></td>
                    <td >
                    <NavLink to="/gestion/edicion-usuario">
                      <button onClick={()=>loadUserDataForEdition(user)} className='font-button'>
                        <FontAwesomeIcon icon='pencil-alt'/>
                      </button>
                    </NavLink>
                    </td>
                </tr>
            
          ))
        }
      </tbody>
  </table>   
)

  }
}

export const ValidateBond = (bond) => {
  if(bond.bailExpire) {
    // Convert act date to MM/DD/YYYY and date Class for format comparison
    let bailExpire = !empty(bond.bailExpire) ? new Date(moment.utc(bond.bailExpire).format('MM/DD/YYYY')) : "";
    // Todays date for comparison without considering the hour
    let today =  new Date(moment.utc(new Date()).format('MM/DD/YYYY'));
  
    if(bailExpire >= today) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

// Conditionate edit button to render dependin on the act Date
export const ConditionalEditButton = (loadBondDataForEdition, bond) => {

  // Convert act date to MM/DD/YYYY and date Class for format comparison
  let actDate = bond.actDate ? new Date(moment.utc(bond.actDate).format('MM/DD/YYYY')) : "";
  // Todays date for comparison without considering the hour
  let today =  new Date(moment.utc(new Date()).format('MM/DD/YYYY'));
  // Edit bond only if is the act Date has not been reached
  if (actDate >= today || bond.guaranteeType?.id === 2){
    return (
      <NavLink to="/editar-fianza">
        <button onClick={()=>loadBondDataForEdition(bond)} className='font-button'>
          <FontAwesomeIcon icon='pencil-alt'/>
        </button>
      </NavLink>
    )
  }else {
    return (
      <div className="no-font-awesome-element"></div>
    )
  }
}
// My Bonds list Table component 
export const BondsTable = (props) => {

  const {bonds, loadBondDataForEdition, openAuditModal} = props;

  if (!bonds) {
    return null
  } else {
    return (
      <table id="bonds-table" className="users-table">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Número de Fianza</th>
            <th>Cliente</th>
            <th>Fecha de acto</th>
            {/* <th>Inicio de Vigencia</th>
            <th>Fin de Vigencia</th>
            <th>Monto de la Fianza</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            bonds.map((bond, i) => (
              <tr key={i}>
                  <td ><h6>{bond.guaranteeType ? bond.guaranteeType.name : 'Propuesta'}</h6></td>
                  <td ><h6>{bond.number}</h6></td>
                  <td ><h6>{bond.client}</h6></td>
                  <td ><h6>{bond.actDate? moment.utc(bond.actDate).format('DD/MM/YYYY'): ""}</h6></td>
                  {/* <td ><h6>{moment.utc(bond.startDate).format('DD/MM/YYYY')}</h6></td>
                  <td ><h6>{moment.utc(bond.endDate).format('DD/MM/YYYY')}</h6></td>
                  <td><h6>B/. {parseFloat(bond.bondAmount).toLocaleString(undefined, {maximumFractionDigits:2})}</h6></td> */}
                  <td >
                    <div className="table-action-button-container">
                      {/* <button onClick={()=>openCommentListModal(bond)} className='font-button'>
                        <FontAwesomeIcon icon='comments'/>
                      </button> */}
                      {ConditionalEditButton(loadBondDataForEdition, bond)}
                      <button onClick={() => openAuditModal(bond)} className='font-button'>
                        <FontAwesomeIcon icon='eye'/>
                      </button>
                    </div>
                  </td>
              </tr>
            ))
          }
        </tbody>
      </table>   
    )
  }
}


// Gov Bonds list Table component 
export const GovBondsTable = (props) => {
  const {bonds, openAuditModal} = props;

  if (!bonds) {
    return null
  } else {
    return (
      <table id="gov-bonds-table" className="users-table">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Nro. de Acto Público</th>
            <th>Nro. de Fianza</th>
            <th>Entidad</th>
            <th>Cliente</th>
            <th>Aseguradora</th>
            {/* <th>Inicio de Vigencia</th>
            <th>Fin de Vigencia</th> */}
            <th>Monto de la Fianza</th> 
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            bonds.map((bond, i) => (
              <tr key={i}>
                  <td>
                    <h6>
                      <div>
                        {bond.guaranteeType ? bond.guaranteeType.name : 'Propuesta'}
                      </div>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <div>
                        {bond.actNum}
                      </div>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <div>
                        {bond.number}
                      </div>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <div>
                        {bond.govEntity?.name}
                      </div>
                    </h6>
                  </td>
                  <td>
                    <h6>  
                      <div>
                        {bond.client}
                      </div>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <div>
                        {bond.insuranceId.name}
                      </div>
                    </h6>
                  </td>
                  {/* <td ><h6>{moment.utc(bond.startDate).format('DD/MM/YYYY')}</h6></td>
                  <td ><h6>{moment.utc(bond.endDate).format('DD/MM/YYYY')}</h6></td> */}
                  <td>
                      <h6>
                        <div>
                          {bond.bondAmount ? 'B/.' : ""} { bond.bondAmount ? parseFloat(bond.bondAmount).toLocaleString(undefined, {maximumFractionDigits:2}) : ""}
                        </div>
                      </h6>
                  </td> 
                  <td >
                    <div className="table-action-button-container">
                      <button onClick={() => openAuditModal(bond)} className='font-button'>
                        <FontAwesomeIcon icon='eye'/>
                      </button>
                    </div>
                  </td>
              </tr>
            ))
          }
        </tbody>
      </table>   
    )
  }
}


// Comments table
export const CommentsTable = (props) => {
  const {comments} = props;

  if (!comments) {
    return (
      <div className="loading-files-spinner-conainer">
      <Loader
           type="Oval"
           color="gray"
           height={30}
           width={320}
           timeout={0} //3 secs
        />
    <h6>Cargando comentarios</h6>
    </div>
    )
  } if (comments.length == 0){
    return (
      <div className="loading-files-spinner-conainer">
        <h6>La fianza no posee comentarios</h6>
      </div>
    )
  }else {
    return (
      <div className="comments-table-container">
        <table id="comments-table" className="users-table">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Comentario</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {
            comments.map((comments, i) => (
              <tr key={i}>
                  <td ><h6>{comments.userName}</h6></td>
                  <td ><h6>{comments.comments}</h6></td>
                  <td ><h6>{comments.createdDate ? moment.utc(comments.createdDate).format('DD/MM/YYYY'): ""}</h6></td>
              </tr>
            ))
          }
        </tbody>
      </table>  
      </div>
    )
  }
}