import React from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../../images//logo-transparent.png";
import login from "../../../images/Login.png";
import "./Header.scss";

const Header = () => {
  return (
    <div className="header">
      <img src={logo} alt=""/>
      
      <NavLink to="/login">
        <div>
          <h6>Iniciar sesión</h6>
          <img src={login} alt=""/>
        </div>
      </NavLink>
    </div>
    );
}
 
export default Header;