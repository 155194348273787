import React from "react";
import apadeaLogo from "../../images/apadea-logo.png";
import ErrorAlert from "../common/ErrorAlert/ErrorAlert";
import { LoadSpinner } from "../common/LoadSpinner/LoadSpinner";
import "./Login.scss";

const LoginButton = (user, loginService, props) => {
  if (user.email === "" || user.password === "") {
    return (
      <div className="button login-button blocked-button">
        <h6>Iniciar sesión</h6>
      </div>
    );
  } else {
    return (
      <div onClick={() => loginService(props)} className="button login-button">
        <h6>Iniciar sesión</h6>
      </div>
    );
  }
};

const Login = (props) => {
  const { inputChange, loginService, user, errors, blockedLogin } = props;

  return (
    <div className="login-container">
      <LoadSpinner
        blocked={blockedLogin}
        text="Iniciando sesión"
        color="#ffffff"
      />

      <div className="login-dashboard">
        <img src={apadeaLogo} alt="" />
        <div className="login-ident-content">
          {/* Titulo */}
          <div>
            <h1 id="login-title" className="title">
              Iniciar sesión
            </h1>
            <div className="login-line"></div>
          </div>

          <label className="input-title"> Usuario</label>
          <input
            onChange={inputChange}
            autoComplete="off"
            className="input"
            type="text"
            name="email"
            placeholder="Usuario"
            value={user.email}
          />
          <ErrorAlert error={errors.email} />

          <label className="input-title">Contraseña</label>
          <input
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                loginService();
              }
            }}
            onChange={inputChange}
            className="input"
            type="password"
            name="password"
            placeholder="Contraseña"
            value={user.password}
          />
          {errors.password && errors.password === 401 ? (
            <ErrorAlert error={"Usuario o contraseña inválida"} />
          ) : (
            ""
          )}
          {errors.password && errors.password === "AUTH-01" ? (
            <div className="pb-5">
              <ErrorAlert
                error={
                  "Su usuario se encuentra bloqueado,  favor enviar un correo a soportedigital@apadea.org para solicitar desbloqueo"
                }
              />
            </div>
          ) : (
            ""
          )}

          {/* <div className="rememeber-password">
            <div className="radio-unselected"></div>
            <h6>Recordar contraseña</h6>
          </div> */}

          <a className="forgot-password" href="/restaurar-contraseña">
            Olvidé mi contraseña
          </a>
        </div>

        <div className="login-ident-content">
          {LoginButton(user, loginService, props)}
        </div>

        <h6 className="login-copyright">
          Apadea 2021. Todos los derechos reservados
        </h6>
      </div>

      <div id="login-slogan-container" className="slogan-container">
        <h1>
          Sumamos esfuerzos <br /> para construir una gran institución
        </h1>
      </div>
    </div>
  );
};

export default Login;
