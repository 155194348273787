import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import folder from "../../../images/folder.svg";
import "./DropZone.scss";

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export const StyledDropzone = (props) => {
  
  const {handleDragAndDropFiles} = props;
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: '.pdf', onChange: (acceptedFiles, fileRejections) => handleDragAndDropFiles(acceptedFiles, fileRejections), onDrop: (acceptedFiles, fileRejections) => handleDragAndDropFiles(acceptedFiles, fileRejections)} );

  

  const style = useMemo(() => ({
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {borderColor: '#0098da'}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);  


  return (
    <div  className="dropzone-container container">
      <div  {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div>
          <img src={folder} alt=""/>
          <h6>Arrastra tus documentos aquí (peso máximo 25MB)</h6>
          <div  className="button load-file-button">
            <h6>Cargar un archivo</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

