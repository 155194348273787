import React from 'react';
import Header from "../common/Header/Header";
import {RecoverPasswordInput} from "../common/Forms/Forms";
import {ForgotPasswordButton} from "../common/Buttons/Buttons";
import {LoadSpinner} from "../common/LoadSpinner/LoadSpinner";
import homeBackground from "../../images/home-background.png";
import "./ForgotPassword.scss";

const ForgottPassword = (props) => {
  const {forgotPasswordFunction, passwordForgotinputChange, forgotPwdForm, blockedLogin} = props;
  return (
    <React.Fragment>
      <LoadSpinner blocked={blockedLogin} text="Enviando solicitud" color="grey"/>
      <Header/>
      <div  className="home-container row">
      <div className="col-12 col-md-7" >
          <h1 className="title">Recuperar Clave</h1>
          <div className="finanzas-search-container">
            <RecoverPasswordInput
            passwordForgotinputChange={passwordForgotinputChange}
            forgotPwdForm={forgotPwdForm}
            />

          <ForgotPasswordButton
          forgotPasswordFunction={forgotPasswordFunction}
          forgotPwdForm={forgotPwdForm}
          />
          </div>
        </div>
        
        <div id="home-slogan-container" className="slogan-container col-md-5 ">
          <h1 >Trabajamos para <br/> ofrecerle el mejor <br/> servicio</h1>
          <img src={homeBackground} alt="img"/>
        </div>
      </div>
    </React.Fragment>
  );
}
 
export default ForgottPassword;