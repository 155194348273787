import React from 'react';
import empty from 'is-empty';
import "./Buttons.scss";
import {NavLink} from "react-router-dom";

// Buton that cancels the file removal from DB
export const CancelFileRemovalFomDBButton = (props) => {
  const {closeDeleteDocummentModal} = props;
  return (
    <div onClick={closeDeleteDocummentModal} className="button cancel-button">
      <h6>Cancelar</h6>
    </div>
  )
}

// Buton that deletes the file from the DB
export const DeleteFileFomDBButton = (props) => {
  const {deleteDocummentFromDBFunction} = props;
    return (
      <div onClick={deleteDocummentFromDBFunction} className="button delete-button">
        <h6>Eliminar documento</h6>
      </div>
    )
}

// Buton that sends the forgot password email to the API
export const ForgotPasswordButton = (props) => {
  const {forgotPasswordFunction, forgotPwdForm} = props;

  if(empty(forgotPwdForm.data.email)){
    return (
      <div className="button login-button blocked-button">
        <h6>Enviar</h6>
      </div>
    )
  } else {
    return (
      <div onClick={() => forgotPasswordFunction()} className="button login-button">
        <h6>Enviar</h6>
      </div>
    )
  }
}

export const SavePasswordButton = (props) => {
  const {updatePasswordFunction,history,passwordUpdateForm} = props;

  let {oldPassword, newPassword, confirmNewPassword} = passwordUpdateForm.data;

  if (empty(oldPassword) || empty(newPassword) || empty(confirmNewPassword)) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    )
  } else {
    return (
      <div onClick={() => updatePasswordFunction(history)} className="button login-button">
        <h6>Guardar</h6>
      </div>
    )
  }
}


// Button that edits the user in the edit user page
export const EditUserButton = (props) => {
  const {userForm, editUserFunction, history} = props;
  const {name, lastName, email, phone} = userForm.data;

  // IF inputs are empty block button
  if (!name || !lastName  || !email  || !phone ) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    )
  }else {
    return (
      <div onClick={()=>editUserFunction(history)} className="button login-button">
        <h6>Guardar</h6>
      </div>
    )
  }
}


// Button that saves the user in the create user page
export const SaveUserButton = (props) => {
  const {userForm, createUserFunction, history} = props;
  const {name, lastName, email, phone} = userForm.data;

  // IF inputs are empty block button
  if (!name  || !lastName  || !email  || !phone ) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    )
  }else {
    return (
      <div onClick={() => createUserFunction(history)} className="button login-button">
        <h6>Guardar</h6>
      </div>
    )
  }
}

export const DownloadReport = (props) => {

  const { reportFunction } = props;

  return (
    <div onClick={() => reportFunction()} className="button login-button">
      <h6>Descargar</h6>
    </div>
  )
}

// Button that saves the audit
// Button that consults the bond by its numner 
export const ConsultBondByIdNumberButton = (props) => {

  const {bondNumber, getBondByNumberFunction}= props; 
  // IF inputs are empty block button
    if (bondNumber === "") {
      return (
          <div id="consult-bond-by-number-button" className="button login-button blocked-button">
            <h6>Buscar</h6>
          </div>

      )
    }else {
      return (
          <div id="consult-bond-by-number-button" onClick={getBondByNumberFunction}  className="button login-button">
            <h6>Buscar</h6>
          </div>
      )
    }
}
// Button that saves the bond audit 
export const SaveAuditButton = (props) => {

  const {comments, createAuditFunction}= props; 
  // IF inputs are empty block button
    if (comments === "") {
      return (
        <div id="save-audit-button" className="button-to-left-container save-button-contanier">
          <div className="button login-button blocked-button">
            <h6>Guardar</h6>
          </div>
        </div>

      )
    }else {
      return (
        <div id="save-audit-button" className="save-button-contanier">
          <div onClick={createAuditFunction} className="button login-button">
            <h6>Guardar</h6>
          </div>
        </div>
      )
    }
}

// Create Bond and Upload fules button
export const CreateBondButton = (props) => {

  const {bondForm , createBondFunction, files}= props; 
    const {
      client,
      number, 
      actDate, 
      guaranteeType,
      bondAmount,
      executionPeriod,
      settlementPeriod,
      defectsOrVices,
      endorsement,
      endorsementTime,
      endorsementAmount,
      endorsementDate,
      resolution,
      govEntity,
      actNum,
      issueDate,
    } = bondForm.data;

    if (guaranteeType === 2) {
      if (guaranteeType === "" || 
          client === "" || 
          number === "" || 
          bondAmount === "" ||
          executionPeriod === "" ||
          settlementPeriod === "" ||
          defectsOrVices === "" ||
          endorsement === "" ||
          endorsementTime === "" ||
          endorsementAmount === "" ||
          endorsementDate === "" ||
          resolution === "" ||
          govEntity === "" ||
          actNum === "" ||
          issueDate === "") 
      {
        return (
        <div id="registro-login-button" className="col-12 save-button-contanier" >
          <div className="button login-button blocked-button">
            <h6>Guardar</h6>
          </div>
        </div>
        )
      } else {
        return (
          <div id="registro-login-button" className="col-12 save-button-contanier" >
            <div onClick={createBondFunction} className="button login-button">
              <h6>Guardar</h6>
            </div>
          </div>
        )
      }
    } else {
      if (guaranteeType === "" || client === "" || number === "" || actDate === "" || files.length === 0 ) {
        return (
        <div id="registro-login-button" className="col-12 save-button-contanier" >
          <div className="button login-button blocked-button">
            <h6>Guardar</h6>
          </div>
        </div>
        )
      } else {
        return (
          <div id="registro-login-button" className="col-12 save-button-contanier" >
            <div onClick={createBondFunction} className="button login-button">
              <h6>Guardar</h6>
            </div>
          </div>
        )
      }
    }
}

// Button that edits the user info and uploads more files 
export const EditBondButton = (props) => {

  const {bondForm , uptadeBondFunction, files, history}= props; 

  const {
    client,
    number, 
    actDate,
    guaranteeType,
    bondAmount,
    executionPeriod,
    settlementPeriod,
    defectsOrVices,
    endorsement,
    endorsementTime,
    endorsementAmount,
    endorsementDate,
    resolution,
    govEntity,
    actNum,
    issueDate,
  } = bondForm.data;

  if (guaranteeType === 2) {
    if (!guaranteeType || 
        !client  || 
        !number || 
        !bondAmount ||
        !executionPeriod ||
        !settlementPeriod ||
        !defectsOrVices ||
        !endorsement ||
        !endorsementTime ||
        !endorsementAmount ||
        !endorsementDate ||
        !resolution ||
        !govEntity ||
        !actNum ||
        !issueDate) {
      return (
        <div id="registro-login-button" className="col-12 save-button-contanier" >
          <div className="button login-button blocked-button">
            <h6>Guardar</h6>
          </div>
        </div>
      )
    } else {
      return (
        <div id="registro-login-button" className="col-12 save-button-contanier" >
          <div onClick={() => uptadeBondFunction(history)} className="button login-button">
            <h6>Guardar</h6>
          </div>
        </div>
      )
    }
  } else {
    if (!guaranteeType || !client  || !number || !actDate 
      || files.length === 0 
      ) {
      return (
        <div id="registro-login-button" className="col-12 save-button-contanier" >
          <div className="button login-button blocked-button">
            <h6>Guardar</h6>
          </div>
        </div>
      )
    } else {
      return (
        <div id="registro-login-button" className="col-12 save-button-contanier" >
          <div onClick={() => uptadeBondFunction(history)} className="button login-button">
            <h6>Guardar</h6>
          </div>
        </div>
      )
    }
  }
}

export const ShowListBonds = (props) => {

  const {getPublicGovBondsListFunction} = props;

  return (
    <NavLink to="/fianzas">
      <div id="show-all-bonds" className='button login-button' onClick={getPublicGovBondsListFunction}>
        <h6>Ver Todas las Fianzas</h6>
      </div>
    </NavLink>
  )
}