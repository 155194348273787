import http from "./httpServices";


// Function that POST data form for creating documents
export async function createDocumentService(datos,bondId, userSession) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${bondId}/document`;

  const config = {
    headers: {     
       'Authorization': `${userSession.sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  

 try {
    const result = await http.post(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


// Returns an array with the list of documents by bond Id 
export async function getDocumentsByBondID(sessionToken, id) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${id}/document`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}



// Returns an array with the list of documents by bond Id 
export async function deleteDocumentService(sessionToken, id) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/document/${id}`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.delete(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}
