import http from "./httpServices";


// Function that creates an audit for a bond
export async function createAudit(datos ,bondId, sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${bondId}/audited`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  

 try {
    const result = await http.post(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


//Function that gets the comments from an Audit
export async function getAuditedComments(sessionToken,bondId) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${bondId}/audited`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}