import React from 'react';
import {Sidebar} from "../common/Sidebar/Sidebar";
import {MenuHeader} from "../common/Menu/MenuHeader";
import {EditBondForm, CreateBondForm} from "../common/Forms/Forms";
import {StyledDropzone} from "../common/DropZone/DropZone";
import {CreateBondButton, EditBondButton} from "../common/Buttons/Buttons";
import {AcceptedFiles, DownloadedRemovableFilesList, DeleteFileModal} from "../common/DocElements/DocElements";
import 'react-datepicker/dist/react-datepicker.css';
// import registerBackground from "../../images/register-background.png";
import {LoadSpinner} from "../common/LoadSpinner/LoadSpinner";
import "./Registro.scss";

// Conditional continue button

export const Registro = (props) => {

  const {
    userSession, 
    logoutService, 
    bondForm, 
    bondForminputChange, 
    createBondFunction,
    handleFromDateChange, 
    handleToDateChange,
    handleDragAndDropFiles,  
    files,  
    removeDocumentsfromList,
    restoreAllForms, 
    handleActDateChange,
    selectGuaranteeType,
    selectGovEntityType,
    selectResolutionType,
    handleEndorsementDateChange,
    handleIssueDateChange,
    gestion
  } = props;


  if (!gestion.guaranteeType || !gestion.govEntitiesType || !gestion.resolutionsType) {
    return null;
  } else {
    return (
      <div className="menu-container">

          <Sidebar 
          userSession={userSession}
          restoreAllForms={restoreAllForms}
          />
          <div className="menu-dashboard container">
          <MenuHeader
            title={"Registrar fianzas"}
            userSession={userSession}
            logoutService={logoutService}
            props={props}
          />

      <LoadSpinner blocked={bondForm.blocked} text="Cargando archivos" color="grey"/>

      <div className="row">
        <div className="register-container col-12 pb-4">
          <div>
            <div className="row d-flex justify-content-center">

              <CreateBondForm
                bondForm={bondForm}
                bondForminputChange={bondForminputChange}
                handleFromDateChange={handleFromDateChange} 
                handleToDateChange={handleToDateChange}
                handleActDateChange={handleActDateChange}
                selectGuaranteeType={selectGuaranteeType}
                selectGovEntityType={selectGovEntityType}
                selectResolutionType={selectResolutionType}
                handleEndorsementDateChange={handleEndorsementDateChange}
                handleIssueDateChange={handleIssueDateChange}
                gestion={gestion}
              />

              <div className="col-11 pl-0 pr-0 pl-md-5 pr-md-5">
                <div className="add-documment-container">
                  <h1 className="title">Agregar documentación</h1>
                  <label  className="input-title">Formato permitido: PDF</label>
                </div>

                <StyledDropzone
                  handleDragAndDropFiles={handleDragAndDropFiles} 
                  files={files} 
                />
                
                <div className="accepted-files-conainer">
                      {files.map( (f,i) => 
                        <AcceptedFiles
                            key={i}
                            index={i}
                            files={f}
                            removeDocumentsfromList={removeDocumentsfromList}
                        />
                      )}
                </div>

                  <CreateBondButton 
                    bondForm={bondForm} 
                    files={files}
                    createBondFunction={createBondFunction} 
                  />
              </div>

            </div>
          </div>
        </div>

        {/* <div id="registro-slogan-container" className="slogan-container col-5 disapear-register">
            <h1 >Nuestro equipo coopera <br/> para mejorar su  <br/>experiencia</h1>
            <img src={registerBackground} alt=""/>
        </div> */}
        
      </div>
            
          </div>
      </div>

      );
  }
}

// Component that edist bonds 
export const EditBondsPage = (props) => {

  const {
    userSession, 
    logoutService, 
    bondForm, 
    bondForminputChange, 
    handleFromDateChange, 
    handleToDateChange,
    handleDragAndDropFiles,  
    files,  
    removeDocumentsfromList, 
    restoreAllForms, 
    uptadeBondFunction, 
    history, 
    openBase64File, 
    handleActDateChange, 
    openDeleteDocummentModal, 
    closeDeleteDocummentModal, 
    deleteDocummentFromDBFunction,
    selectGuaranteeType,
    selectGovEntityType,
    selectResolutionType,
    handleEndorsementDateChange,
    handleIssueDateChange,
    gestion
  } = props;

  if (!gestion.guaranteeType || !gestion.govEntitiesType || !gestion.resolutionsType) {
    return null;
  } else {
    return (
      <div className="menu-container">
  
          <DeleteFileModal
          bondForm={bondForm}
          closeDeleteDocummentModal={closeDeleteDocummentModal}
          deleteDocummentFromDBFunction={deleteDocummentFromDBFunction}
          />
  
          <Sidebar 
          userSession={userSession}
          restoreAllForms={restoreAllForms}
          />
          <div className="menu-dashboard container">
          <MenuHeader
            title={"Editar fianzas"}
            userSession={userSession}
            logoutService={logoutService}
            props={props}
          />
  
          <LoadSpinner blocked={bondForm.blocked} text="Cargando archivos" color="grey"/>
  
          <div className="row">
            <div className="register-container col-12 pb-4">
              <div>
                <div className="row d-flex justify-content-center">
  
                  <EditBondForm
                    bondForm={bondForm}
                    bondForminputChange={bondForminputChange}
                    handleFromDateChange={handleFromDateChange} 
                    handleToDateChange={handleToDateChange}
                    handleActDateChange={handleActDateChange}
                    selectGuaranteeType={selectGuaranteeType}
                    selectGovEntityType={selectGovEntityType}
                    selectResolutionType={selectResolutionType}
                    handleEndorsementDateChange={handleEndorsementDateChange}
                    handleIssueDateChange={handleIssueDateChange}
                    gestion={gestion}
                  />
  
                  <div className="col-11 pl-0 pr-0 pl-md-5 pr-md-5">
                    <div className="add-documment-container">
                      <h1 className="title">Agregar documentación</h1>
                      <label  className="input-title">Formato permitido: PDF</label>
                    </div>
    
                    <StyledDropzone
                    handleDragAndDropFiles={handleDragAndDropFiles} 
                    files={files} 
                    />
    
                    <div className="accepted-files-conainer">
                      {files.map( (f,i) => 
                      <AcceptedFiles
                        key={i}
                        index={i}
                        files={f}
                        removeDocumentsfromList={removeDocumentsfromList}
                      />
                      )}
                      
                      <DownloadedRemovableFilesList 
                      documents={bondForm.data.files}
                      openBase64File={openBase64File}
                      openDeleteDocummentModal={openDeleteDocummentModal}
                      />
    
                    </div>
    
                    <EditBondButton 
                      bondForm={bondForm} 
                      files={files.length !== 0 ? files : bondForm.data.files}
                      uptadeBondFunction={uptadeBondFunction} 
                      history={history}
                    />
                  </div>
  
                </div>
  
              </div>
            </div>
            {/* <div id="registro-slogan-container" className="slogan-container col-5 disapear-register">
                <h1 >Nuestro equipo coopera <br/> para mejorar su  <br/>experiencia</h1>
                <img src={registerBackground} alt=""/>
            </div> */}
            
          </div>
            
          </div>
      </div>
  
      );
  }
}