import http from "./httpServices";

export async function getUserList(sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/user`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Returns an array with the type of users 
export async function getUserType(sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/usertype`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


// Returns an array with the type of government account 
export async function getGovType(sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/govtype`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}

// Function that POST data form for creating users
export async function createUser(datos, sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/user`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  

 try {
    const result = await http.post(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


// Function that POST data form for creating users
export async function updateUser(datos, sessionToken, id) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/user/${id}`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  


 try {
    const result = await http.put(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}

