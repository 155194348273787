import React from 'react';
import pdf from "../../../images/pdf.svg";
import remove from "../../../images/delete.svg";
import Loader from 'react-loader-spinner'
import {DeleteFileFomDBButton, CancelFileRemovalFomDBButton} from "../Buttons/Buttons";
import "./DocElements.scss";

export const AcceptedFiles = (props) => {

  const {index, files,removeDocumentsfromList } = props;

  return (
    <div key={index} className="accepted-files-container">
      <div>
      <img src={pdf} alt=""/>
      <h6>{files.name}  {"   " + (files.size/1048576).toFixed(2)} MB</h6>
      </div>
      <img onClick={() => removeDocumentsfromList(index)} src={remove} alt="image"/>          
  </div>
  )
}


// Downloaded documents that can be deleted only on the insurance view
export const DownloadedRemovableFilesList = (props) => {

  const {documents,openBase64File, openDeleteDocummentModal} = props;

  if (documents.length > 0) {
    return (
      <React.Fragment>
         {documents.map((d,i) =>
          <div id="downloaded-files"  key={i} className="accepted-files-container">
            <div onClick={()=> openBase64File(d)} >
            <img src={pdf} alt=""/>
            <h6>{d.fileName}</h6>
            </div>
            <img className="remove-file-from-db-button" onClick={()=> openDeleteDocummentModal(i)} src={remove} alt="image"/>          
          </div>
          )}
      </React.Fragment>
    )
  }

  if (documents === "") {
    return (
      <div className="loading-files-spinner-conainer">
        <Loader
             type="Oval"
             color="gray"
             height={30}
             width={320}
             timeout={0} //3 secs
          />
      <h6>Cargando archivos</h6>
      </div>
    )
  }

  if (documents.length === 0) {
    return (
      <div id="no-files-avaliable-message" className="accepted-files-list-container">
        <h6>No hay documentos disponibles</h6>
      </div>
    )
  }
}

// Downloaded documents for the public bond consult
export const DownloadedFilesList = (props) => {

  const {documents,openBase64File} = props;

  if (documents.length > 0) {
    return (
      <React.Fragment>
         {documents.map((d,i) =>
          <div id="downloaded-files" onClick={()=> openBase64File(d)} key={i} className="accepted-files-container">
            <div>
            <img src={pdf} alt=""/>
            <h6>{d.fileName}</h6>
            </div>
          </div>
          )}
      </React.Fragment>
    )
  }

  if (documents === "") {
    return (
      <div className="loading-files-spinner-conainer">
        <Loader
             type="Oval"
             color="gray"
             height={30}
             width={320}
             timeout={0} //3 secs
          />
      <h6>Cargando archivos</h6>
      </div>
    )
  }

  if (documents.length === 0) {
    return (
      <div id="no-files-avaliable-message" className="accepted-files-list-container">
        <h6>No hay documentos disponibles</h6>
      </div>
    )
  }
}


export const DownloadedFiles = (props) => {

  const {documents,openBase64File} = props;

  if (documents.length > 0) {
    return (
      <div className="accepted-files-list-container">
        {documents.map((d,i) =>
          <div id="downloaded-files" onClick={()=> openBase64File(d)} key={i} className="accepted-files-container">
            <div>
            <img src={pdf} alt=""/>
            <h6>{d.fileName}</h6>
            </div>
          </div>
          )}
      </div>
    )
  }

  if (documents === "") {
    return (
      <div className="loading-files-spinner-conainer">
        <Loader
             type="Oval"
             color="gray"
             height={30}
             width={320}
             timeout={0} //3 secs
          />
      <h6>Cargando archivos</h6>
      </div>
    )
  }

  if (documents.length === 0) {
    return (
      <div className="accepted-files-list-container">
        <h6 id="no-files-avaliable-message">No hay documentos disponibles</h6>
      </div>
    )
  }
}

// Modal that deletes or not the selected documment
export const DeleteFileModal = (props) => {

  const {bondForm, closeDeleteDocummentModal, deleteDocummentFromDBFunction} = props;
  const {files} = bondForm.data;
  if (bondForm.deleteModal === true) {
    return (
      <div className="load-spinner-container">
          <div className="delete-bond-document-modal">
            <h1 className="title">¿Está seguro de que desea borrar el documento?</h1>
            <h6>Si procede el documento <b>{files[bondForm.deleteDocumentIndex].fileName}</b>  será borrado del sistema</h6>
            <div className="remove-bond-document-button-container">

              <CancelFileRemovalFomDBButton 
              closeDeleteDocummentModal={closeDeleteDocummentModal}
              />

              <DeleteFileFomDBButton
              deleteDocummentFromDBFunction={deleteDocummentFromDBFunction}
              />
              
            </div>
          </div>
      </div>
    )
  }else {
    return null;
  }
}