import React from "react";
import { DownloadedFiles } from "../DocElements/DocElements";
import arrow from "../../../images/arrow.png";
import moment from "moment";
import "./BondComment.scss";

// Modal for posting comments
export const BondComment = (props) => {
  const {
    audit: {
      show,
      number,
      documents,
      guaranteeType,
      client,
      actDate,
      bondAmount,
      govEntity,
      actNum,
      issueDate,
      procedureType,
      awardType,
      contractObject,
      executionPeriod,
      settlementPeriod,
      orderDate,
      defectsOrVices,
      validityEndDate,
      bailExpire,
      daysToExpire,
      endorsement,
      endorsementTime,
      endorsementAmount,
      totalSecured,
      endorsementDate,
      endorsementGuarantor,
      endorsementDueDays,
      physicalProgress,
      financialProgress,
      substantialRecord,
      substantialDate,
      finalAct,
      finalDate,
      resolution,
      updatedDate,
      description,
      referencePrice,
      adjudicatedAmount,
      guarantorName,
      insurance,
    },
    closeAuditModal,
    openBase64File,
  } = props;

  if (show === true) {
    return (
      <div className="bond-comment-container">
        <div className="row">
          <div className="col-md-3 col-lg-4"></div>
          <div className="bond-comment-sidebar-container col-12 col-md-9 col-lg-8">
            <div id="bond-comment-sidebar" onClick={closeAuditModal}>
              <h6 id="call-to-action-user-list" className="title">
                Volver
              </h6>
              <img src={arrow} alt="" />
            </div>
            <div style={{ overflowY: 'scroll', height: 700}}>
              <div className="row w-100 justify-content-start text-left">
                <div className="col-12 col-md-6 col-lg-3">
                  <h6 className="title-comment">Tipo de fianza:</h6>
                  <h6>{guaranteeType}</h6>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <h6 className="title-comment">Número de fianza:</h6>
                  <h6>{number}</h6>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <h6 className="title-comment">Cliente:</h6>
                  <h6>{client}</h6>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <h6 className="title-comment">Aseguradora:</h6>
                  <h6>{insurance}</h6>
                </div>
                {guaranteeType !== "Cumplimiento" && (
                  <div className="col-12 col-md-6 col-lg-3">
                    <h6 className="title-comment">Fecha de acto:</h6>
                    <h6>
                      {actDate ? moment.utc(actDate).format("DD/MM/YYYY") : ""}
                    </h6>
                  </div>
                )}
                {guaranteeType === "Cumplimiento" && (
                  <>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Entidad:</h6>
                      <h6>{govEntity}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Número de acto público:</h6>
                      <h6>{actNum}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Descripción:</h6>
                      <h6>{description}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Precio de referencia:</h6>
                      <h6>{referencePrice}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Tipo de procedimiento:</h6>
                      <h6>{procedureType}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Tipo de adjudicación:</h6>
                      <h6>{awardType}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Objeto contractual:</h6>
                      <h6>{contractObject}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Monto adjudicado:</h6>
                      <h6>
                        {adjudicatedAmount ? "B/." : ""}{" "}
                        {adjudicatedAmount
                          ? parseFloat(adjudicatedAmount).toLocaleString(
                              undefined,
                              { maximumFractionDigits: 2 }
                            )
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Nombre del fiador de la fianza o garantía:
                      </h6>
                      <h6>{guarantorName}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Fecha de emisión:</h6>
                      <h6>
                        {issueDate
                          ? moment.utc(issueDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Período de ejecución (días):
                      </h6>
                      <h6>{executionPeriod}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6>
                        <span className="title-comment">
                          Periodo de liquidación (días)
                        </span>{" "}
                        - Suman al periodo de ejecución para llegar a la
                        vigencia:
                      </h6>
                      <h6>{settlementPeriod}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Fecha de orden de proceder:
                      </h6>
                      <h6>
                        {orderDate
                          ? moment.utc(orderDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Fecha fin de vigencia:</h6>
                      <h6>
                        {validityEndDate
                          ? moment.utc(validityEndDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Periodo de vigencia defectos o vicios:
                      </h6>
                      <h6>{defectsOrVices}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Vencimiento de la fianza:
                      </h6>
                      <h6>
                        {bailExpire
                          ? moment.utc(bailExpire).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Días por Vencer:</h6>
                      <h6>{daysToExpire}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Monto de la fianza:</h6>
                      <h6>
                        {bondAmount ? "B/." : ""}{" "}
                        {bondAmount
                          ? parseFloat(bondAmount).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Endoso:</h6>
                      <h6>{endorsement}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Endoso tiempo (días):</h6>
                      <h6>{endorsementTime}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Monto del endoso:</h6>
                      <h6>
                        {endorsementAmount ? "B/." : ""}{" "}
                        {endorsementAmount
                          ? parseFloat(endorsementAmount).toLocaleString(
                              undefined,
                              { maximumFractionDigits: 2 }
                            )
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Total afianzado:</h6>
                      <h6>
                        {totalSecured ? "B/." : ""}{" "}
                        {totalSecured
                          ? parseFloat(totalSecured).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Fecha de emisión del endoso:
                      </h6>
                      <h6>
                        {endorsementDate
                          ? moment.utc(endorsementDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Nombre del fiador del endoso:
                      </h6>
                      <h6>{endorsementGuarantor}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Días por vencer del endoso:
                      </h6>
                      <h6>{endorsementDueDays}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Porcentaje de avance físico:
                      </h6>
                      <h6>{physicalProgress}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Porcentaje de avance financiero:
                      </h6>
                      <h6>{financialProgress}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Acta sustancial:</h6>
                      <h6>{substantialRecord}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">
                        Fecha de acta sustancial:
                      </h6>
                      <h6>
                        {substantialDate
                          ? moment.utc(substantialDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Acta final:</h6>
                      <h6>{finalAct}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Fecha de acta final:</h6>
                      <h6>
                        {finalDate
                          ? moment.utc(finalDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Resolución:</h6>
                      <h6>{resolution}</h6>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6 className="title-comment">Fecha de actualización:</h6>
                      <h6>
                        {updatedDate
                          ? moment.utc(updatedDate).format("DD/MM/YYYY")
                          : ""}
                      </h6>
                    </div>
                  </>
                )}
              </div>
              <h6>Documentos anexos a la fianza</h6>
              <DownloadedFiles
                documents={documents}
                openBase64File={openBase64File}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

// Bonds list of comments
export const BondCommentList = (props) => {
  const { comments, closeCommentListModal } = props;

  if (comments.show === true) {
    return (
      <div className="bond-comment-container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="bond-comment-sidebar-container col-12 col-md-8">
            <div id="bond-comment-sidebar" onClick={closeCommentListModal}>
              <h6 id="call-to-action-user-list" className="title">
                Volver
              </h6>
              <img src={arrow} alt="" />
            </div>
            <h6>Comentarios de la fianza</h6>
            <CommentsTable comments={comments.data} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
