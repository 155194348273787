import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import ErrorAlert from "../common/ErrorAlert/ErrorAlert";
import { DownloadReport } from "../common/Buttons/Buttons";
import NumberFormat from "react-number-format";

registerLocale("es", es);

export const SelectInsuranceType = (props) => {
  const { reportForm, gestion, selectReportInsurance, userSession } = props;

  if (userSession.userType === "Aseguradora") {
    reportForm.data.insurance = userSession.insuranceId;
    return (
      <div className="register-input-container col-12">
        <label className="input-title">Seleccione una de las aseguradoras</label>
        <select
          onChange={selectReportInsurance}
          name="insurance"
          value={reportForm.data.insurance}
        >
        <option className="option-place-holder" key={userSession.insuranceId} value={userSession.insuranceId}>
          {userSession.insuranceName}
        </option>
        </select>
        <ErrorAlert error={reportForm.errors.insurance} />
      </div>
    );
  } else {
    return (
      <div className="register-input-container col-12">
        <label className="input-title">Seleccione una de las aseguradoras</label>
        <select
          onChange={selectReportInsurance}
          name="insurance"
          value={reportForm.data.insurance}
        >
          <option className="option-place-holder" value="">
            Seleccionar
          </option>
          {gestion.insurance && gestion.insurance.map((u) => (
            <option key={u.id} value={u.id}>
              {u.name}
            </option>
          ))}
        </select>
        <ErrorAlert error={reportForm.errors.insurance} />
      </div>
    );
  }
};

export const SelectGovEntity = (props) => {
  const { reportForm, gestion, selectReportGovEntity, userSession } = props;

  if (userSession.userType === "Gobierno") {
    reportForm.data.govEntity = userSession.govTypeId;
    return (
      <div className="register-input-container col-12">
        <label className="input-title">
          Seleccione una entidad gubernamental
        </label>
        <select
          onChange={selectReportGovEntity}
          name="govEntity"
          value={reportForm.data.govEntity}
        >
        <option className="option-place-holder" key={userSession.govTypeId} value={userSession.govTypeId}>
          {userSession.govType}
        </option>
        </select>
        <ErrorAlert error={reportForm.errors.govEntity} />
      </div>
    );
  } else {
    return (
      <div className="register-input-container col-12">
        <label className="input-title">
          Seleccione una entidad gubernamental
        </label>
        <select
          onChange={selectReportGovEntity}
          name="govEntity"
          value={reportForm.data.govEntity}
        >
          <option className="option-place-holder" value="">
            Seleccionar
          </option>
          {gestion.govEntitiesType && gestion.govEntitiesType.map((u) => (
            <option key={u.id} value={u.id}>
              {u.name}
            </option>
          ))}
        </select>
        <ErrorAlert error={reportForm.errors.govEntity} />
      </div>
    );
  }
};

export const SelecGuaranteeTypeInput = (props) => {
  const { selectReportGuaranteeType, reportForm, gestion, userSession } = props;

  if (userSession.userType === "Gobierno") {
    return (
      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Tipo de garantía</label>
        <select
          onChange={selectReportGuaranteeType}
          name="guaranteeType"
          value={reportForm.data.guaranteeType = '2'}
        >
        <option className="option-place-holder" key={2} value="2">
          Cumplimiento
        </option>
        </select>
        <ErrorAlert error={reportForm.errors.guaranteeType} />
      </div>
    );
  } else {
    return (
      <div className="register-input-container col-12 col-md-6">
        <label className="input-title">Seleccione el tipo de garantía</label>
        <select
          onChange={selectReportGuaranteeType}
          name="guaranteeType"
          value={reportForm.data.guaranteeType}
        >
          <option className="option-place-holder" value="">
            Seleccionar
          </option>
          {gestion.guaranteeType &&
            gestion.guaranteeType.map((u) => (
              <option key={u.id} value={u.id}>
                {u.name}
              </option>
            ))}
        </select>
        <ErrorAlert error={reportForm.errors.guaranteeType} />
      </div>
    );
  }
};

export const Report = (props) => {
  const {
    handleReportStartDateChange,
    handleReportEndDateChange,
    selectReportInsurance,
    selectReportGovEntity,
    reportFormInputChange,
    selectReportGuaranteeType,
    reportFunction,
    reportForm,
    gestion,
    userSession,
  } = props;

  return (
    <div className="row">
      <div className="report-container col-12 col-lg-8">
        <div>
          <div className="row">
            <SelecGuaranteeTypeInput
              selectReportGuaranteeType={selectReportGuaranteeType}
              reportForm={reportForm}
              gestion={gestion}
              userSession={userSession}
            />
            <div className="register-input-container col-12 col-md-6">
              <label className="input-title">Número de fianza</label>
              <input
                onChange={reportFormInputChange}
                autoComplete="off"
                className="input"
                type="text"
                name="number"
                placeholder="Indique el número de fianza"
                value={reportForm.data.number}
              />
              <ErrorAlert error={reportForm.errors.number} />
            </div>
            <div className="register-input-container col-12 col-md-6">
              <label className="input-title">Monto fianza (mínimo)</label>
              <NumberFormat
                onChange={reportFormInputChange}
                name="bondAmountStart"
                className="input"
                placeholder="Monto fianza"
                value={reportForm.data.bondAmountStart}
                thousandSeparator={true}
                decimalSeparator="."
              />
              <ErrorAlert error={reportForm.errors.bondAmountStart} />
            </div>
            <div className="register-input-container col-12 col-md-6">
              <label className="input-title">Monto fianza (máximo)</label>
              <NumberFormat
                onChange={reportFormInputChange}
                name="bondAmountEnd"
                className="input"
                placeholder="Monto fianza"
                value={reportForm.data.bondAmountEnd}
                thousandSeparator={true}
                decimalSeparator="."
              />
              <ErrorAlert error={reportForm.errors.bondAmountEnd} />
            </div>
            <div className="register-input-date-container col-12 col-md-6">
              <div>
                <div className="register-input-container register-input-date-mb">
                  <label className="input-title">Fecha de Emision/Acto (desde)</label>
                  <DatePicker
                    onChange={handleReportStartDateChange}
                    selected={reportForm.data.startDate}
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <ErrorAlert error={reportForm.errors.startDate} />
            </div>
            <div className="register-input-date-container col-12 col-md-6">
              <div>
                <div className="register-input-container register-input-date-mb">
                  <label className="input-title">Fecha de Emision/Acto (hasta)</label>
                  <DatePicker
                    onChange={handleReportEndDateChange}
                    selected={reportForm.data.endDate}
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <ErrorAlert error={reportForm.errors.endDate} />
            </div>
            <SelectInsuranceType
              reportForm={reportForm}
              gestion={gestion}
              selectReportInsurance={selectReportInsurance}
              userSession={userSession}
            />
            <SelectGovEntity
              reportForm={reportForm}
              gestion={gestion}
              selectReportGovEntity={selectReportGovEntity}
              userSession={userSession}
            />
            <div className="col-12 save-button-contanier">
              <DownloadReport 
                reportFunction={reportFunction}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
