// import http from "./httpServices";
import axios from "axios";

export async function reportDownload(datos, sessionToken) {
  let url = process.env.REACT_APP_API_URL;
  url = url + `/bond-csv`;

  try {
    axios({
      url: url,
      method: 'POST',
      responseType: 'blob',
      data: datos,
      headers: {
        'Authorization': `${sessionToken}`,
      } 
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'fianzas.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return 'ok';
  } catch (error) {
    return error.response;
  }
}
