import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faComments,
  faEye,
  faEyeSlash,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import empty from "is-empty";
import Joi from "joi-browser";
import moment from "moment";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ForgottPassword from "./components/ForgotPassword/ForgotPassword";
import { Home } from "./components/Home/Home";
import Login from "./components/Login/Login";
import { EditBondsPage, Registro } from "./components/Registro/Registro";
import { ChangePasswordPage, Menu } from "./components/common/Menu/Menu";
import { ProtectedRoute } from "./components/common/ProtectedRoute/ProtectedRoute";
import { getInsurranceList } from "./services/InsuranceService";
import { createAudit, getAuditedComments } from "./services/auditService";
import {
  createBond,
  getMyBondsListGov,
  getBondByNumber,
  getMyBondsList,
  updateBondById,
  getPublicBondsList,
} from "./services/bondService";
import {
  createDocumentService,
  deleteDocumentService,
  getDocumentsByBondID,
} from "./services/documentService";
import { getGuaranteeList } from "./services/guaranteeService";
import { getResolutionList } from "./services/resolutionService";
import { getGovEntitiesList } from "./services/govEntitiesService";
import { signIn } from "./services/loginService";
import { forgotPassword, updatePassword } from "./services/passwordService";
import {
  createUser,
  getGovType,
  getUserList,
  getUserType,
  updateUser,
} from "./services/userListService";
import { reportDownload } from "./services/reportService";

library.add(faPencilAlt, fab, faEye, faEyeSlash, faComments, faCheckSquare);

class App extends Component {
  state = {
    comments: { show: false, data: "" },
    userSession: {}, //Data returned by the API after the login
    user: { email: "", password: "" }, // User data
    errors: { email: "", password: "" }, //Errors in login inputs
    blockedLogin: false, //Boolean thta show the load spinner in the login
    loadList: false, //Boolean that show the load spiner in the lists
    gestion: {
      userType: "",
      govType: "",
      guaranteeType: "",
      resolutionsType: "",
      govEntitiesType: "",
      userInsurance: "",
    }, // User and gov type and insurances
    userList: {}, //User list dynamic table component
    reportForm: {
      data: {
        number: "",
        startDate: "",
        endDate: "",
        bondAmountStart: "",
        bondAmountEnd: "",
        govEntity: "",
        insurance: "",
        guaranteeType: "",
      },
      errors: {
        number: "",
        startDate: "",
        endDate: "",
        bondAmountStart: "",
        bondAmountEnd: "",
        govEntity: "",
        insurance: "",
        guaranteeType: "",
      },
    },
    userForm: {
      data: {
        idUserType: "",
        name: "",
        lastName: "",
        email: "",
        phone: "",
        idInsurance: null,
        idGovType: null,
      },
      errors: {
        idUserType: "",
        name: "",
        lastName: "",
        email: "",
        phone: "",
        idInsurance: "",
        idGovType: "",
      },
    }, // Create user form data
    // bondForm: {data:{}, errors:{}},//Create bond form data
    bondsList: {}, //Bonds list
    bondForm: {
      blocked: false,
      deleteModal: false,
      deleteDocumentSelected: "",
      deleteDocumentIndex: "",
      canDeleteDoc: true,
      data: {
        client: "",
        number: "",
        bondAmount: "",
        projectAmount: "",
        startDate: "",
        endDate: "",
        actDate: "",
        files: "",
        guaranteeType: "",
        endorsement: "",
        endorsementTime: "",
        endorsementAmount: "",
        endorsementGuarantor: "",
        endorsementDate: "",
        executionPeriod: "",
        settlementPeriod: "",
        defectsOrVices: "",
        govEntity: "",
        resolution: "",
        actNum: "",
        issueDate: "",
      },
      errors: {
        client: "",
        number: "",
        bondAmount: "",
        projectAmount: "",
        actDate: "",
        guaranteeType: "",
        endorsement: "",
        endorsementTime: "",
        endorsementAmount: "",
        endorsementGuarantor: "",
        endorsementDate: "",
        executionPeriod: "",
        settlementPeriod: "",
        defectsOrVices: "",
        govEntity: "",
        resolution: "",
        actNum: "",
        issueDate: "",
      },
    }, //Create bond form data
    query: "", // For search box
    files: [], //Files for uploading
    passwordUpdateForm: {
      data: { oldPassword: "", newPassword: "", confirmNewPassword: "" },
      errors: { oldPassword: "", newPassword: "", confirmNewPassword: "" },
    },
    passwordShown: false, // For show/hide password
    passwordUpdate: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    audit: {
      show: false,
      comments: "",
      error: "",
      documents: "",
      blocked: false,
    },
    forgotPwdForm: {
      data: { email: "" },
      errors: { email: "" },
      blocked: false,
    },
    forgotPwd: { email: "" },
    consultedBond: {
      data: { bondNumber: "", bond: "", files: "" },
      errors: { bondNumber: "" },
    },
  };

  componentDidMount() {
    toast.configure();

    // Get variables from session storage
    let userSession = this.getFromSessionStorage("userSession");
    let gestion = this.getFromSessionStorage("gestion");

    // Search in session storage
    // Update variables in state
    if (userSession !== null) {
      this.setState({ userSession });
    }

    if (gestion !== null) {
      this.setState({ gestion });
    }

    let userList = !empty(userSession)
      ? userSession.userType == "Administrador"
        ? this.getUserListFunction(userSession.sessionToken)
        : ""
      : "";

    if (!empty(userList)) {
      userList.orgtableData = userList.data;
      this.setState({ userList });
    }

    let bondsList = !empty(userSession)
      ? userSession.userType == "Aseguradora"
        ? this.getMyBondsListFunction(
            userSession.sessionToken,
            userSession.userId
          )
        : userSession.userType == "Gobierno"
        ? this.getGovBondsListFunction(userSession.sessionToken)
        : ""
      : "";

    if (!empty(bondsList)) {
      bondsList.orgtableData = bondsList.data;
      this.setState({ bondsList });
    }
  }
  // Function that gets the user list from the API
  getUserListFunction = async (token) => {
    let data = await getUserList(token);
    let userList = {};

    if (!data) {
      return null;
    }

    // Error response in case not AUTH
    if (data.status !== 200) {
      console.log("Error", data);
    }

    // Get the user list from the API
    if (data.status == 200) {
      // Get data grom data response
      data = data.data;

      // User list object default structure
      userList.isFiltered = false;
      userList.offset = 0;
      userList.perPage = 10;
      userList.currentPage = 0;
      let slice = data.slice(
        userList.offset,
        userList.offset + userList.perPage
      );
      userList.pageCount = Math.ceil(data.length / userList.perPage);
      userList.data = slice;
      userList.orgtableData = data;

      this.setState({ userList });
      this.saveOnSessionStorage("userList", userList);
    }
  };

  // Function that get the user and gov type list from the DB
  getUserTypeAndGovFunction = async (token) => {
    let gestion = { ...this.state.gestion };
    let userType = await getUserType(token);
    let govType = await getGovType(token);
    let insurance = await getInsurranceList(token);
    let govEntitiesType = await getGovEntitiesList(token);
    let guaranteeType = await getGuaranteeList(token);

    if (
      !userType ||
      !govType ||
      !insurance ||
      !govEntitiesType ||
      !guaranteeType
    ) {
      this.notify("Usuario Invalido", "error");
      return null;
    }

    // Error response in case not AUTH
    if (
      userType.status !== 200 ||
      govType.status !== 200 ||
      insurance.status !== 200 ||
      govEntitiesType.status !== 200 ||
      guaranteeType.status !== 200
    ) {
      console.log("Error");
    }

    // Get the user type and
    if (
      userType.status == 200 &&
      govType.status == 200 &&
      insurance.status == 200 &&
      govEntitiesType.status == 200 &&
      guaranteeType.status == 200
    ) {
      // Get data grom data response
      gestion.userType = userType.data;
      gestion.govType = govType.data;
      gestion.insurance = insurance.data;
      gestion.govEntitiesType = govEntitiesType.data;
      gestion.guaranteeType = guaranteeType.data;
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }
  };

  // Function that loads mode data in the users tables
  loadMoreUserData() {
    let userList = { ...this.state.userList };

    const data = userList.isFiltered
      ? userList.orgFilteredData
      : userList.orgtableData;

    // Get ammount of pages and slice data
    userList.pageCount = Math.ceil(data.length / userList.perPage);
    userList.data = data.slice(
      userList.offset,
      userList.offset + userList.perPage
    );

    this.setState({ userList });
  }

  // Function that Handles pagination events on the users table
  handleUserPageClick = (e) => {
    let userList = { ...this.state.userList };
    const selectedPage = e.selected;

    userList.offset = selectedPage * userList.perPage;
    userList.currentPage = selectedPage;

    this.setState({ userList }, () => {
      this.loadMoreUserData();
    });
  };

  // Function that loads mode data in the bonds tables
  loadMoreBondData() {
    let bondsList = { ...this.state.bondsList };

    const data = bondsList.isFiltered
      ? bondsList.orgFilteredData
      : bondsList.orgtableData;

    // Get ammount of pages and slice data
    bondsList.pageCount = Math.ceil(data.length / bondsList.perPage);
    bondsList.data = data.slice(
      bondsList.offset,
      bondsList.offset + bondsList.perPage
    );

    this.setState({ bondsList });
  }

  // Function that Handles pagination events on the users table
  handleBondPageClick = (e) => {
    let bondsList = { ...this.state.bondsList };
    const selectedPage = e.selected;

    bondsList.offset = selectedPage * bondsList.perPage;
    bondsList.currentPage = selectedPage;

    this.setState({ bondsList }, () => {
      this.loadMoreBondData();
    });
  };

  // Function that handles event changes on the user input
  inputChange = async ({ currentTarget: input }) => {
    let user = { ...this.state.user };
    let errors = { email: "", password: "" };
    // Trim input to avoid erros on the login
    user[input.name] = input.value.trim();
    // Convert email to lowercase
    if (input.name == "email") {
      user[input.name] = user[input.name].toLowerCase(user[input.name]);
    }
    this.setState({ user, errors });
  };

  // Function that validates the user login inputs
  validateLogin = () => {
    let user = { ...this.state.user };
    let errors = { ...this.state.errors };
    let canPost = true;
    if (user.email == "") {
      errors.email = "Usuario requerido";
      canPost = false;
    }

    if (user.password == "") {
      errors.password = "Contraseña requerida";
      canPost = false;
    }
    this.setState({ errors });
    return canPost;
  };

  // Function that validates the user and password inputs
  validate = () => {
    const options = { abortEarly: false };
    const user = {
      email: this.state.user.email,
      password: this.state.user.password,
    };

    //Validation with Joi
    var schema = {
      email: Joi.string().required().label("User"),
      password: Joi.string().required().label("Password"),
    };

    const { error } = Joi.validate(user, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  // Function that handles the login and expects a Bearer token in response
  loginService = async (props) => {
    let user = { ...this.state.user };
    let errors = { ...this.state.errors };
    let canPost = this.validateLogin();
    // Show load spinner
    let blockedLogin = true;
    this.setState({ blockedLogin });

    if (canPost == false) {
      // Unlock Button
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }

    let userSession = await signIn(user);

    if (!userSession) {
      this.notify("Connection Error", "error");
      // Unlock Button
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }
    // If the login was successfull saves the data in session storage
    if (userSession.status == 200) {
      userSession = userSession.data;
      errors = { email: "", password: "" };
      this.setState({ userSession, errors });
      this.saveOnSessionStorage("userSession", userSession);

      // Get data an redirect to respective route depending on user type
      this.protectLoginRoutes(userSession, props);
      // Get The user list function
      // Unlock Button
      blockedLogin = false;
      this.setState({ blockedLogin });
    }
    if (userSession.status == 401) {
      errors.password = userSession.data.errorCode;
      this.setState({ errors });
      // Unlock Button
      blockedLogin = false;
      this.setState({ blockedLogin });
    }
  };

  // Get data an redirect to respective route depending on user type
  protectLoginRoutes = async (userSession, props) => {
    // Login process for Admin user
    if (userSession.userType == "Administrador") {
      this.getUserListFunction(userSession.sessionToken); //Get the user list from DB
      this.getUserTypeAndGovFunction(userSession.sessionToken); //Get the user types and gov lists
      props.history.push(`/gestion/registro`);
    }

    // Login process for Insurance user
    if (userSession.userType == "Aseguradora") {
      this.getMyBondsListFunction(userSession.sessionToken, userSession.userId);
      props.history.push(`/registrar-fianza`);
    }
    // Login process for Government user
    if (userSession.userType == "Gobierno") {
      this.getGovBondsListFunction(userSession.sessionToken);
      props.history.push(`/gestion/fianzas`);
    }
  };

  // Function that handles the Create user selectors
  selectUserType = ({ currentTarget: input }) => {
    let userForm = { ...this.state.userForm };
    userForm.data[input.name] = input.value !== "" ? parseInt(input.value) : "";

    // Restart the selectors default value depending on the user type
    if (userForm.data.idUserType == 1) {
      userForm.data.idGovType = null;
      userForm.data.idInsurance = null;
    }
    if (userForm.data.idUserType == 2) {
      userForm.data.idGovType = null;
    }

    if (userForm.data.idUserType == 3) {
      userForm.data.idInsurance = null;
    }

    this.setState({ userForm });
  };

  selectGuaranteeType = ({ currentTarget: input }) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data[input.name] = input.value !== "" ? parseInt(input.value) : "";

    this.setState({ bondForm });
  };

  selectResolutionType = ({ currentTarget: input }) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data[input.name] = input.value !== "" ? parseInt(input.value) : "";

    this.setState({ bondForm });
  };

  selectGovEntityType = ({ currentTarget: input }) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data[input.name] = input.value !== "" ? parseInt(input.value) : "";

    this.setState({ bondForm });
  };

  selectReportInsurance = ({ currentTarget: input }) => {
    let reportForm = { ...this.state.reportForm };
    reportForm.data[input.name] =
      input.value !== "" ? parseInt(input.value) : "";

    this.setState({ reportForm });
  };

  selectReportGovEntity = ({ currentTarget: input }) => {
    let reportForm = { ...this.state.reportForm };
    reportForm.data[input.name] =
      input.value !== "" ? parseInt(input.value) : "";

    this.setState({ reportForm });
  };

  selectReportGuaranteeType = ({ currentTarget: input }) => {
    let reportForm = { ...this.state.reportForm };
    reportForm.data[input.name] =
      input.value !== "" ? parseInt(input.value) : "";

    this.setState({ reportForm });
  };

  reportFunction = async () => {
    let reportForm = { ...this.state.reportForm };
    let userSession = { ...this.state.userSession };
    let canPost = true;

    reportForm.errors = {
      number: "",
      startDate: "",
      endDate: "",
      bondAmountStart: "",
      bondAmountEnd: "",
      govEntity: "",
      insurance: "",
      guaranteeType: "",
    };

    if (
      reportForm.data.guaranteeType == "" &&
      reportForm.data.number == "" &&
      reportForm.data.bondAmountStart == "" &&
      reportForm.data.bondAmountEnd == "" &&
      (reportForm.data.startDate == "" || reportForm.data.startDate == null) &&
      (reportForm.data.endDate == "" || reportForm.data.endDate == null) &&
      reportForm.data.govEntity == "" &&
      reportForm.data.insurance == ""
    ) {
      this.notify(
        "Debe ingresar al menos un filtro para descargar el reporte.",
        "error"
      );
      canPost = false;
    }

    reportForm.data.bondAmountStart = reportForm.data.bondAmountStart.replace(
      /,/g,
      ""
    );
    reportForm.data.bondAmountEnd = reportForm.data.bondAmountEnd.replace(
      /,/g,
      ""
    );

    if (
      reportForm.data.bondAmountStart != "" &&
      reportForm.data.bondAmountEnd != ""
    ) {
      if (
        parseInt(reportForm.data.bondAmountStart) >
        parseInt(reportForm.data.bondAmountEnd)
      ) {
        reportForm.errors.bondAmountStart =
          "El monto debe ser menor o igual al monto máximo.";
        canPost = false;
      }
    }

    if (
      reportForm.data.startDate != "" &&
      reportForm.data.startDate != null &&
      reportForm.data.endDate != "" &&
      reportForm.data.endDate != null
    ) {
      if (moment(reportForm.data.startDate) > moment(reportForm.data.endDate)) {
        reportForm.errors.startDate =
          "La fecha debe ser menor o igual a la fecha fin.";
        canPost = false;
      }
    }

    this.setState({ reportForm });

    if (canPost) {
      let dataToPost = {
        number: reportForm.data.number ? reportForm.data.number : null,
        startDate: reportForm.data.startDate
          ? reportForm.data.startDate.toISOString().slice(0, 10)
          : "",
        endDate: reportForm.data.endDate
          ? reportForm.data.endDate.toISOString().slice(0, 10)
          : "",
        startAmount: reportForm.data.bondAmountStart
          ? reportForm.data.bondAmountStart
          : null,
        endAmount: reportForm.data.bondAmountEnd
          ? reportForm.data.bondAmountEnd
          : null,
        govEntityId: reportForm.data.govEntity
          ? reportForm.data.govEntity
          : null,
        insuranceId: reportForm.data.insurance
          ? reportForm.data.insurance
          : null,
        guaranteeTypeId: reportForm.data.guaranteeType
          ? reportForm.data.guaranteeType
          : null,
      };

      let response = await reportDownload(dataToPost, userSession.sessionToken);

      if (!response) {
        this.notify("Error al descargar el archivo", "error");
        console.log(response);
      }
    }
  };

  //Function that posts new users to the API
  createUserFunction = async (history) => {
    let userSession = { ...this.state.userSession };
    let userForm = { ...this.state.userForm };

    // Validate the user form inputs
    let canPost = this.validateUserForm();
    if (canPost == true) {
      let response = await createUser(userForm.data, userSession.sessionToken);

      // No response from API
      if (!response) {
        this.notify("Connection Error", "error");
      }

      // User created
      if (response.status == 200) {
        this.notify("Usuario creado satisfactoriamente", "success");
        this.getUserListFunction(userSession.sessionToken); //Get the user list from DB
        // Delete data from form inputs
        userForm.data = {
          idUserType: "",
          name: "",
          lastName: "",
          email: "",
          phone: "",
          idInsurance: null,
          idGovType: null,
        };
        this.setState({ userForm });
        // Borar datos de los inputs
      }
      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
      }
      //  Show expired session messagen and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
      }
    }
  };

  // Function that validates the userform inputs
  validateUserForm = () => {
    let userForm = { ...this.state.userForm };
    let canPost = true;
    //Delete error messages
    userForm.errors = {
      idUserType: "",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      idInsurance: "",
      idGovType: "",
    };

    // Validate any insutance company is selected
    if (userForm.data.idUserType == 2 && userForm.data.idInsurance == null) {
      userForm.errors.idInsurance = "Aseguradora requerida";
      canPost = false;
    }

    // Validate if avy government entity is selected
    if (userForm.data.idUserType == 3 && userForm.data.idGovType == null) {
      userForm.errors.idGovType = "Entidad gubernamental requerida";
      canPost = false;
    }

    // Evaluate required inputs
    if (userForm.data.name == "") {
      userForm.errors.name = "Nombre requerido";
      canPost = false;
    }
    if (userForm.data.lastName == "") {
      userForm.errors.lastName = "Apellido requerido";
      canPost = false;
    }
    if (userForm.data.email == "") {
      userForm.errors.email = "Correo requerido";
      canPost = false;
    }
    if (userForm.data.phone == "") {
      userForm.errors.phone = "Teléfono requerido";
      canPost = false;
    }

    // Validate email format
    if (!this.validateEmailFormat(userForm.data.email)) {
      userForm.errors.email = "Ingrese un email válido";
      canPost = false;
    }

    // Validate phone format
    if (!this.validateNumericFormat(userForm.data.phone)) {
      userForm.errors.phone = "Ingrese un teléfono válido";
      canPost = false;
    }

    this.setState({ userForm });
    return canPost;
  };

  //Function that handles the show/hide of the password
  setPasswordShownValue = () => {
    let passwordShown = !this.state.passwordShown;
    this.setState({ passwordShown });
  };

  // Function that validates the update password inputs
  passwordFormValidate = () => {
    const options = { abortEarly: false };
    const update = {
      oldPassword: this.state.passwordUpdate.oldPassword,
      newPassword: this.state.passwordUpdate.newPassword,
      confirmNewPassword: this.state.passwordUpdate.confirmNewPassword,
    };

    //Validation with Joi
    var schema = {
      oldPassword: Joi.string().required().label("oldPassword"),
      newPassword: Joi.string().required().label("newPassword"),
      confirmNewPassword: Joi.string().required().label("confirmNewPassword"),
    };

    const { error } = Joi.validate(update, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  //Function that posts a password update to the API
  updatePasswordFunction = async (history) => {
    let userSession = { ...this.state.userSession };
    let passwordUpdateForm = { ...this.state.passwordUpdateForm };

    // Validate the user form inputs
    let canPost = this.validateUpdatePasswordForm();

    if (canPost == true) {
      let body = {
        oldPassword: passwordUpdateForm.data.oldPassword,
        newPassword: passwordUpdateForm.data.newPassword,
      };

      let response = await updatePassword(
        body,
        userSession.userId,
        userSession.sessionToken
      );

      // No response from API
      if (!response) {
        this.notify("Connection Error", "error");
      }

      // password updated
      if (response.status == 200) {
        this.notify("Contraseña actualizada satisfactoriamente", "success");
        // Delete data from form inputs
        passwordUpdateForm.data = {
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        };
        passwordUpdateForm.errors = {
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        };
        this.setState({ passwordUpdateForm });
        // Borar datos de los inputs
      }

      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
      }

      //  Show expired session messagen and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
      }
    }
  };

  // Function that validates the update password inputs
  validateUpdatePasswordForm = () => {
    let passwordUpdateForm = { ...this.state.passwordUpdateForm };
    let canPost = true;
    //Delete error messages
    passwordUpdateForm.errors = {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };

    // Evaluate required inputs
    if (passwordUpdateForm.data.oldPassword == "") {
      passwordUpdateForm.errors.oldPassword = "Clave actual requerida";
      canPost = false;
    }
    if (passwordUpdateForm.data.newPassword == "") {
      passwordUpdateForm.errors.newPassword = "Nueva clave requerida";
      canPost = false;
    }
    if (passwordUpdateForm.data.confirmNewPassword == "") {
      passwordUpdateForm.errors.confirmNewPassword =
        "Confirmación de nueva clave requerida";
      canPost = false;
    }

    // Evaluate inputs length
    if (passwordUpdateForm.data.newPassword.length < 8) {
      passwordUpdateForm.errors.newPassword =
        "La clave debe tener al menos 8 caracteres";
      canPost = false;
    }
    if (passwordUpdateForm.data.confirmNewPassword.length < 8) {
      passwordUpdateForm.errors.confirmNewPassword =
        "La clave debe tener al menos 8 caracteres";
      canPost = false;
    }

    // Evaluate oldPassword and newPassword are different
    if (
      passwordUpdateForm.data.oldPassword == passwordUpdateForm.data.newPassword
    ) {
      passwordUpdateForm.errors.newPassword =
        "La clave nueva no puede ser igual a la anterior";
      canPost = false;
    }

    // Evaluate confirmNewPassword and newPassword are equals
    if (
      passwordUpdateForm.data.newPassword !=
      passwordUpdateForm.data.confirmNewPassword
    ) {
      passwordUpdateForm.errors.confirmNewPassword = "Las claves no coinciden";
      canPost = false;
    }

    this.setState({ passwordUpdateForm });
    return canPost;
  };

  // Function that handles event changes on the create user inputs
  userForminputChange = async ({ currentTarget: input }) => {
    let userForm = { ...this.state.userForm };
    // Trim input to avoid erros on the login
    userForm.data[input.name] = input.value.trimStart();
    // Convert email to lowercase
    if (input.name == "email") {
      userForm.data[input.name] = userForm.data[input.name].toLowerCase(
        userForm.data[input.name]
      );
    }
    this.setState({ userForm });
  };

  // Function that handles event changes on the update password inputs
  passwordForminputChange = async ({ currentTarget: input }) => {
    let passwordUpdateForm = { ...this.state.passwordUpdateForm };

    // Trim input to avoid erros on the login
    passwordUpdateForm.data[input.name] = input.value.trimStart();

    this.setState({ passwordUpdateForm });
  };

  reportFormInputChange = async ({ currentTarget: input }) => {
    let reportForm = { ...this.state.reportForm };

    reportForm.data[input.name] = input.value.trimStart();

    if (
      (input.name == "bondAmountStart" || input.name == "bondAmountEnd") &&
      reportForm.data[input.name] !== ""
    ) {
      Number.prototype.countDecimals = function () {
        if (Math.floor(this.valueOf()) == this.valueOf()) return 0;
        return this.toString().split(".")[1].length || 0;
      };

      let number = parseFloat(reportForm.data[input.name]);
      number = number.countDecimals();
      if (number > 2) {
        reportForm.data[input.name] = parseFloat(
          reportForm.data[input.name]
        ).toFixed(2);
      }
    }

    this.setState({ reportForm });
  };

  // Function that handles event changes on the register bond inputs
  bondForminputChange = async ({ currentTarget: input }) => {
    let bondForm = { ...this.state.bondForm };
    // Trim input to avoid erros on the login
    bondForm.data[input.name] = input.value.trimStart();
    if (
      (input.name == "projectAmount" ||
        input.name == "bondAmount" ||
        input.name == "endorsementAmount") &&
      bondForm.data[input.name] !== ""
    ) {
      Number.prototype.countDecimals = function () {
        if (Math.floor(this.valueOf()) == this.valueOf()) return 0;
        return this.toString().split(".")[1].length || 0;
      };

      let number = parseFloat(bondForm.data[input.name]);
      number = number.countDecimals();
      if (number > 2) {
        bondForm.data[input.name] = parseFloat(
          bondForm.data[input.name]
        ).toFixed(2);
      }
    }

    this.setState({ bondForm });
  };

  // Function that validates the userform inputs
  validateBondForm = () => {
    let bondForm = { ...this.state.bondForm };
    let canPost = true;
    //Delete error messages
    bondForm.errors = {
      client: "",
      number: "",
      bondAmount: "",
      projectAmount: "",
      startDate: "",
      endDate: "",
      actDate: "",
      guaranteeType: "",
      endorsement: "",
      endorsementTime: "",
      endorsementAmount: "",
      endorsementGuarantor: "",
      endorsementDate: "",
      executionPeriod: "",
      settlementPeriod: "",
      defectsOrVices: "",
      govEntity: "",
      resolution: "",
      actNum: "",
      issueDate: "",
    };

    // Evaluate required inputs
    if (bondForm.data.client == "") {
      bondForm.errors.client = "Cliente requerido";
      canPost = false;
    }
    if (bondForm.data.number == "") {
      bondForm.errors.number = "Número de fianza es requerido";
      canPost = false;
    }
    // if (bondForm.data.projectAmount == "") {bondForm.errors.projectAmount = "Monto del proyecto requerido";canPost = false;}
    // if (bondForm.data.bondAmount == "") {bondForm.errors.bondAmount = "Monto de fianza requerido";canPost = false;}

    // if (!this.validateIfEndDateIsGreaterEqual(bondForm.data.startDate, bondForm.data.endDate)) {
    //   bondForm.errors.startDate = "Ingrese un valor valido para el fin de fianza";
    //   canPost = false;
    // }

    this.setState({ bondForm });
    return canPost;
  };

  //Function that posts new users to the API
  createBondFunction = async (history) => {
    let userSession = { ...this.state.userSession };
    let bondForm = { ...this.state.bondForm };
    let gestion = { ...this.state.gestion };
    let files = this.state.files;

    bondForm.data.projectAmount = bondForm.data.projectAmount.replace(/,/g, "");
    bondForm.data.bondAmount = bondForm.data.bondAmount.replace(/,/g, "");
    bondForm.data.endorsementAmount = bondForm.data.endorsementAmount.replace(
      /,/g,
      ""
    );
    bondForm.data.executionPeriod = bondForm.data.executionPeriod.replace(
      /,/g,
      ""
    );
    bondForm.data.settlementPeriod = bondForm.data.settlementPeriod.replace(
      /,/g,
      ""
    );
    bondForm.data.defectsOrVices = bondForm.data.defectsOrVices.replace(
      /,/g,
      ""
    );
    bondForm.data.endorsementTime = bondForm.data.endorsementTime.replace(
      /,/g,
      ""
    );

    // Validate file length before validating
    let canPostFile = this.validateIfExceedsWeight(files, 25);
    // Validate the user form inputs
    let canPost = this.validateBondForm();

    // IF valid parse int, filesize and Put ID

    if (canPost == true && canPostFile == true && bondForm.blocked == false) {
      // Block post button
      bondForm.blocked = true;
      this.setState({ bondForm });

      // Data to post to the API
      let dataToPost = {
        number: bondForm.data.number,
        client: bondForm.data.client,
        bondAmount: bondForm.data.bondAmount ? bondForm.data.bondAmount : null,
        projectAmount: null, // Now it is not necessary
        startDate: null, // Now it is not necessary
        endDate: null, // Now it is not necessary
        isPrivate: false,
        status: "PENDIENTE",
        userId: userSession.userId,
        actDate: bondForm.data.actDate
          ? bondForm.data.actDate.toISOString().slice(0, 10)
          : "", //Change to BD format
        govEntity: bondForm.data.govEntity ? bondForm.data.govEntity : null,
        description: "",
        contractObject: null,
        guaranteeType: bondForm.data.guaranteeType
          ? bondForm.data.guaranteeType
          : null,
        executionPeriod: bondForm.data.executionPeriod
          ? bondForm.data.executionPeriod
          : null,
        settlementPeriod: bondForm.data.settlementPeriod
          ? bondForm.data.settlementPeriod
          : null,
        orderDate: "",
        defectsOrVices: bondForm.data.defectsOrVices
          ? bondForm.data.defectsOrVices
          : null,
        bailExpire: "",
        daysToExpire: null,
        endorsement: bondForm.data.endorsement,
        endorsementTime: bondForm.data.endorsementTime
          ? bondForm.data.endorsementTime
          : null,
        endorsementAmount: bondForm.data.endorsementAmount
          ? bondForm.data.endorsementAmount
          : null,
        totalSecured: null,
        endorsementDate: bondForm.data.endorsementDate
          ? bondForm.data.endorsementDate.toISOString().slice(0, 10)
          : "",
        endorsementGuarantor: gestion.userInsurance[0].name,
        endorsementDueDays: null,
        physicalProgress: null,
        financialProgress: null,
        substantialRecord: "",
        substantialDate: "",
        finalAct: "",
        finalDate: "",
        regulatoryType: bondForm.data.resolution
          ? bondForm.data.resolution
          : null,
        actNum: bondForm.data.actNum ? bondForm.data.actNum : null,
        issueDate: bondForm.data.issueDate
          ? bondForm.data.issueDate.toISOString().slice(0, 10)
          : "",
        procedureType: null,
        awardType: null,
        // projectAmount: parseFloat(bondForm.data.projectAmount).toFixed(2),//DB requires float not string
        // bondAmount: parseFloat(bondForm.data.bondAmount).toFixed(2), //DB requires float not string
        // startDate: bondForm.data.startDate.toISOString().slice(0, 10), //Change to BD format
        // endDate: bondForm.data.endDate.toISOString().slice(0, 10), //Change to BD format
      };

      console.log(dataToPost);

      let response = await createBond(dataToPost, userSession.sessionToken);

      // No response from API
      if (!response) {
        this.notify("Connection Error", "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      }

      console.log(response);

      // Bond created
      if (response.status == 200) {
        // Delete data from form inputs
        this.getMyBondsListFunction(
          userSession.sessionToken,
          userSession.userId
        ); //Get the bonds list from DB
        if (this.state.files.length > 0) {
         // Crate document for new bonds
          let documentCreated = await this.createDocument(
            response.data.id,
            false
          ); // Get bondID then create documments
          if (documentCreated == true) {
            this.notify("Fianza creada satisfactoriamente", "success");
          }
        } else {
          bondForm.blocked = false;
          this.restoreBondState();
          this.setState({ files: [] }); //Delete the loaded files
          this.getMyBondsListFunction(
            userSession.sessionToken,
            userSession.userId
          );
          this.notify("Fianza creada satisfactoriamente", "success");
        }
      }
      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      }
      //  Show expired session message and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      }
    }
  };

  // Function that refresh teh bonds list when switching between tabs
  refreshBondList = async () => {
    let userSession = this.state.userSession;
    // requst teh bond list
    let bondsList = !empty(userSession)
      ? userSession.userType == "Aseguradora"
        ? this.getMyBondsListFunction(
            userSession.sessionToken,
            userSession.userId
          )
        : userSession.userType == "Gobierno"
        ? this.getGovBondsListFunction(userSession.sessionToken)
        : ""
      : "";
    // IF there is a bond list update it
    if (!empty(bondsList)) {
      bondsList.orgtableData = bondsList.data;
      this.setState({ bondsList });
    }
  };

  refreshUserList = async () => {
    let userSession = this.state.userSession;

    this.getUserListFunction(userSession.sessionToken);
  };

  // Returns all forms to their default values
  restoreAllForms = () => {
    this.restoreBondState();
    this.restoreUserState();
    this.refreshBondList();
    this.refreshUserList(); //Refresh the bond list every when switchign between tabs
  };

  //Restores bond State defeult values
  restoreUserState = () => {
    let userForm = { ...this.state.userForm };
    userForm.data = {
      idUserType: "",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      idInsurance: null,
      idGovType: null,
    };
    userForm.errors = {
      idUserType: "",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      idInsurance: "",
      idGovType: "",
    };

    this.setState({ userForm });
  };
  //Restores bond State defeult values
  restoreBondState = () => {
    let bondForm = { ...this.state.bondForm };
    let files = this.state.files;
    bondForm.data = {
      client: "",
      number: "",
      bondAmount: "",
      projectAmount: "",
      startDate: "",
      endDate: "",
      files: "",
      guaranteeType: "",
      endorsement: "",
      endorsementTime: "",
      endorsementAmount: "",
      endorsementGuarantor: "",
      executionPeriod: "",
      settlementPeriod: "",
      defectsOrVices: "",
      govEntity: "",
      resolution: "",
      actNum: "",
      issueDate: "",
    };
    bondForm.errors = {
      client: "",
      number: "",
      bondAmount: "",
      projectAmount: "",
      startDate: "",
      endDate: "",
      guaranteeType: "",
      endorsement: "",
      endorsementTime: "",
      endorsementAmount: "",
      endorsementGuarantor: "",
      executionPeriod: "",
      settlementPeriod: "",
      defectsOrVices: "",
      govEntity: "",
      resolution: "",
      actNum: "",
      issueDate: "",
    };
    // Unlock post button
    bondForm.blocked = false;
    //Remove loaded files
    files = [];
    this.setState({ bondForm, files });
  };

  // Function that handles the from date selector change
  handleFromDateChange = (e) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data.startDate = e;
    this.setState({ bondForm });
  };

  // Function that handles the to date selector change
  handleToDateChange = (e) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data.endDate = e;
    this.setState({ bondForm });
  };

  // Function that handles the act date selector change
  handleActDateChange = (e) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data.actDate = e;
    this.setState({ bondForm });
  };

  // Function that handles the endorsement selector change
  handleEndorsementDateChange = (e) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data.endorsementDate = e;
    this.setState({ bondForm });
  };

  // Function that handles the endorsement selector change
  handleIssueDateChange = (e) => {
    let bondForm = { ...this.state.bondForm };
    bondForm.data.issueDate = e;
    this.setState({ bondForm });
  };

  // Function that handles the start report date selector change
  handleReportStartDateChange = (e) => {
    let reportForm = { ...this.state.reportForm };
    reportForm.data.startDate = e;
    this.setState({ reportForm });
  };

  // Function that handles the start report date selector change
  handleReportEndDateChange = (e) => {
    let reportForm = { ...this.state.reportForm };
    reportForm.data.endDate = e;
    this.setState({ reportForm });
  };

  // Handle expired session and redirects to login
  handleExpiredSession = (history) => {
    this.notify("Su sesión ha expirado", "info");
    sessionStorage.clear();
    history.push(`/login`);
  };

  // Function that handles the logout request
  logoutService = (props) => {
    let userList = {}; // Clear user list
    let bondsList = {}; // Clear bond list
    this.setState({ userList, bondsList });
    sessionStorage.clear();
    props.history.push(`/login`);
  };
  // Function that stores the variable in sesion storage
  saveOnSessionStorage = (name, input) => {
    const data = JSON.stringify(input);
    sessionStorage.setItem(`${name}`, data);
  };

  // Function that get the variable from the session storage
  getFromSessionStorage = (name) => {
    let data = sessionStorage.getItem(`${name}`);
    data = JSON.parse(data);
    return data;
  };

  // Function that capitalize the first letter of each word in a string
  capitalizeFirstLetter = (string) => {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // Function that validates email format return booleans
  validateEmailFormat(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Function that validates if inputs is number
  validateNumericFormat(phone) {
    const re = /^[0-9]*$/;
    return re.test(phone);
  }

  // Function that validates if the end date is greater than the start date
  validateIfEndDateIsGreaterEqual(startDate, endDate) {
    return startDate <= endDate;
  }
  // Toast notification custom  Message
  notify = (message, type) => {
    if (type == "success") {
      toast.success(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoCLose: 3000,
        className: "Ts-op",
      });
    }
    if (type == "info") {
      toast.info(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "Ts-op",
      });
    }
    if (type == "error") {
      toast.error(`${message}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "Ts-op",
      });
    }
  };

  // Function that handles event on the search box in user list
  searchUserBox = (query) => {
    let userFiltered;
    let userList = { ...this.state.userList };

    if (!empty(query)) {
      userFiltered = this.state.userList.orgtableData.filter(
        (list) =>
          list.name.toLowerCase().includes(query.toLowerCase()) ||
          list.email.toLowerCase().includes(query.toLowerCase()) ||
          list.lastName.toLowerCase().includes(query.toLowerCase()) ||
          list.userTypeName.toLowerCase().includes(query.toLowerCase()) ||
          list.insuranceName.toLowerCase().includes(query.toLowerCase()) ||
          list.govTypeName.toLowerCase().includes(query.toLowerCase())
      );

      userList.data = userFiltered;
      userList.orgFilteredData = userFiltered;
      userList.pageCount = Math.ceil(userFiltered.length / userList.perPage);
      // Slice users to max # users per page
      userList.data = userList.data.slice(
        userList.offset,
        userList.offset + userList.perPage
      );
      userList.isFiltered = true;
      this.setState({ userList });
    } else if (empty(query)) {
      userList.isFiltered = false;
      // Gets back the original number of pages
      userList.pageCount = Math.ceil(
        userList.orgtableData.length / userList.perPage
      );
      // Slice data to # users per page
      userList.data = userList.orgtableData.slice(
        userList.offset,
        userList.offset + userList.perPage
      );

      // The data to display per page
      this.setState({ userList });
    }
  };

  // Function that gets the user list from the API
  getMyBondsListFunction = async (token, userId) => {
    let gestion = { ...this.state.gestion };
    let guaranteeType = await getGuaranteeList(token);

    // Error response in case not AUTH
    if (guaranteeType.status !== 200) {
      console.log("Error", guaranteeType);
    }

    // Get the user list from the API
    if (guaranteeType.status == 200) {
      gestion.guaranteeType = guaranteeType.data;
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }

    let resolutionsType = await getResolutionList(token);

    // Error response in case not AUTH
    if (resolutionsType.status !== 200) {
      console.log("Error", resolutionsType);
    }

    // Get the user list from the API
    if (resolutionsType.status == 200) {
      gestion.resolutionsType = resolutionsType.data;
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }

    let govEntitiesType = await getGovEntitiesList(token);

    // Error response in case not AUTH
    if (govEntitiesType.status !== 200) {
      console.log("Error", govEntitiesType);
    }

    // Get the user list from the API
    if (govEntitiesType.status == 200) {
      gestion.govEntitiesType = govEntitiesType.data;
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }

    let insurance = await getInsurranceList(token);

    // Error response in case not AUTH
    if (insurance.status !== 200) {
      console.log("Error", insurance);
    }

    // Get the user list from the API
    if (insurance.status == 200) {
      let userSession = { ...this.state.userSession };
      let data = insurance.data;
      gestion.userInsurance = data.filter(
        (insurance) => insurance.id === userSession.insuranceId
      );
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }

    let data = await getMyBondsList(token, userId);
    let bondsList = {};

    if (!data) {
      return null;
    }

    // Error response in case not AUTH
    if (data.status !== 200) {
      console.log("Error", data);
    }

    // Get the user list from the API
    if (data.status == 200) {
      // Get data from data response
      data = data.data;

      // User list object default structure
      bondsList.isFiltered = false;
      bondsList.offset = 0;
      bondsList.perPage = 10;
      bondsList.currentPage = 0;
      let slice = data.slice(
        bondsList.offset,
        bondsList.offset + bondsList.perPage
      );
      bondsList.pageCount = Math.ceil(data.length / bondsList.perPage);
      bondsList.data = slice;
      bondsList.orgtableData = data;

      this.setState({ bondsList });
    }
  };

  // Function that gets the public list bond from the API
  getPublicGovBondsListFunction = async () => {
    let data = await getPublicBondsList();
    let bondsList = {};
    let loadList = true;
    this.setState({ loadList });

    // Remember to change it with a toast Notification
    if (!data) {
      return null;
    }

    // Error response in case not AUTH
    if (data.status !== 200) {
      loadList = false;
      this.setState({ loadList });
    }

    // Get the bond list from the API
    if (data.status == 200) {
      // Get data from data response
      data = data.data;

      // bond list object default structure
      bondsList.isFiltered = false;
      bondsList.offset = 0;
      bondsList.perPage = 10;
      bondsList.currentPage = 0;
      let slice = data.slice(
        bondsList.offset,
        bondsList.offset + bondsList.perPage
      );
      bondsList.pageCount = Math.ceil(data.length / bondsList.perPage);
      bondsList.data = slice;
      bondsList.orgtableData = data;

      this.setState({ bondsList });
      loadList = false;
      this.setState({ loadList });
    }
  };

  // Function that gets the user list from the API
  getGovBondsListFunction = async (token) => {
    let userSession = this.getFromSessionStorage("userSession");
    let data = await getMyBondsListGov(token, userSession.userId);
    let bondsList = {};
    let loadList = true;
    this.setState({ loadList });

    /**Cuando el rol es Gobierno, la lista de las entidades gubernamentables se solicita
     * y al obtenerla, se settea en el objeto gestion, al igual que la lista de aseguradoras
     */
    let govEntitiesType = await getGovEntitiesList(token);
    let insurance = await getInsurranceList(token);
    let gestion = { ...this.state.gestion };

    // Error response in case not AUTH
    if (govEntitiesType.status !== 200) {
      console.log("Error", govEntitiesType);
    }

    // Get the user list from the API
    if (govEntitiesType.status == 200) {
      gestion.govEntitiesType = govEntitiesType.data;
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }

    // Error response in case not AUTH
    if (insurance.status !== 200) {
      console.log("Error", insurance);
    }

    // Get the user list from the API
    if (insurance.status == 200) {
      gestion.insurance = insurance.data;
      this.setState({ gestion });
      this.saveOnSessionStorage("gestion", gestion);
    }

    // Remember to change it with a toast Notification
    if (!data) {
      return null;
    }

    // Error response in case not AUTH
    if (data.status !== 200) {
      loadList = false;
      this.setState({ loadList });
    }

    // Get the bond list from the API
    if (data.status == 200) {
      // Get data from data response
      data = data.data;

      // bond list object default structure
      bondsList.isFiltered = false;
      bondsList.offset = 0;
      bondsList.perPage = 10;
      bondsList.currentPage = 0;
      let slice = data.slice(
        bondsList.offset,
        bondsList.offset + bondsList.perPage
      );
      bondsList.pageCount = Math.ceil(data.length / bondsList.perPage);
      bondsList.data = slice;
      bondsList.orgtableData = data;

      this.setState({ bondsList });
      loadList = false;
      this.setState({ loadList });
    }
  };

  // Function that handles event on the search box in user list
  searchBondsBox = (query, userType) => {
    let bondFiltered;
    let bondsList = { ...this.state.bondsList };
    let loadList = true;
    this.setState({ loadList });
    if (!empty(query)) {
      // Filter data for governmetnt user type
      if (userType === "Gobierno") {
        bondFiltered = this.state.bondsList.orgtableData.filter(
          (list) =>
            (!empty(list.bondAmount)
              ? list.bondAmount.toString().includes(query)
              : "") ||
            (!empty(list.guaranteeType)
              ? list.guaranteeType.name
                  .toLowerCase()
                  .includes(query.toLowerCase())
              : "") ||
            (!empty(list.govEntity)
              ? list.govEntity.name.toLowerCase().includes(query.toLowerCase())
              : "") ||
            (!empty(list.insuranceId)
              ? list.insuranceId.name
                  .toLowerCase()
                  .includes(query.toLowerCase())
              : "") ||
            (!empty(list.actNum)
              ? list.actNum.toLowerCase().includes(query.toLowerCase())
              : "") ||
            (!empty(list.client)
              ? list.client.toLowerCase().includes(query.toLowerCase())
              : "") ||
            (!empty(list.number)
              ? list.number.toLowerCase().includes(query.toLowerCase())
              : "")
        );
      }

      if (userType === "Aseguradora") {
        bondFiltered = this.state.bondsList.orgtableData.filter(
          (list) =>
            list.client.toLowerCase().includes(query.toLowerCase()) ||
            list.number.toLowerCase().includes(query.toLowerCase()) ||
            (!empty(list.guaranteeType)
              ? list.guaranteeType.name
                  .toLowerCase()
                  .includes(query.toLowerCase())
              : "") ||
            moment
              .utc(list.actDate)
              .format("DD/MM/YYYY")
              .toString()
              .includes(query)
        );
      }

      bondsList.data = bondFiltered;
      bondsList.orgFilteredData = bondFiltered;
      bondsList.pageCount = Math.ceil(bondFiltered.length / bondsList.perPage);
      // Slice Bonss to max # bonds per page
      bondsList.data = bondsList.data.slice(
        bondsList.offset,
        bondsList.offset + bondsList.perPage
      );
      bondsList.isFiltered = true;
      this.setState({ bondsList });
      loadList = false;
      this.setState({ loadList });
    } else if (empty(query)) {
      bondsList.isFiltered = false;
      // Gets back the original number of pages
      bondsList.pageCount = Math.ceil(
        bondsList.orgtableData.length / bondsList.perPage
      );
      // Slice Bonss to max # bonds per page
      bondsList.data = bondsList.orgtableData.slice(
        bondsList.offset,
        bondsList.offset + bondsList.perPage
      );
      // The data to display per page
      this.setState({ bondsList });
      loadList = false;
      this.setState({ loadList });
    }
  };

  // Handle files drag and drop
  handleDragAndDropFiles = (accepted, rejected) => {
    let files = this.state.files;
    files = files.concat(accepted);
    // Error message in case PDF
    if (rejected.length > 0) {
      toast.error(`Solo se permiten archivos en formato .pdf`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "Ts-op",
      });
    }

    this.setState({ files });
    this.validateIfExceedsWeight(files, 25);
  };

  // Function that validates if the files exceed the max weight in MB
  validateIfExceedsWeight = (files, maxWeight) => {
    let totalWeight = 0;
    // Sum the weight of all the files
    for (let i = 0; i < files.length; i++) {
      totalWeight = totalWeight + files[i].size;
    }
    // Validate if the files weight more than 25Mb
    if (totalWeight / 1048576 > maxWeight) {
      toast.error(
        `La carga de documentos no puede superar los ${maxWeight}MB`,
        { position: toast.POSITION.TOP_RIGHT, className: "Ts-op" }
      );
      return false;
    } else {
      return true;
    }
  };

  // Function that posts documments to the server
  // isOldBond defines if the bond is created (false) or edite (true)
  createDocument = async (id, isOldBond) => {
    let userSession = { ...this.state.userSession };
    let bondForm = { ...this.state.bondForm };
    let files = this.state.files;
    let canPost = this.validateIfExceedsWeight(files, 25);

    if (canPost == true) {
      let bondId = id; //Temporalmente 1 por defecto luego se cambia con el flujo
      let datos = new FormData();
      for (let i = 0; i < files.length; i++) {
        datos.append("files", files[i], files[i].path);
      }
      // Indicates the DB if the bond is being created or edited
      datos.append("isOldBond", isOldBond);
      let response = await createDocumentService(datos, bondId, userSession);

      // In case No response from API
      if (!response) {
        this.notify("Connection Error", "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
        return false;
      }

      // Bond created
      if (response.status == 200) {
        // Delete data from form inputs
        this.restoreBondState();
        this.setState({ files: [] }); //Delete the loaded files
        this.getMyBondsListFunction(
          userSession.sessionToken,
          userSession.userId
        ); //Get the bonds list from DB
        return true;
      } else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
        return false;
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
        return false;
      }
      //  Show expired session message and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
        return false;
      }
      // Show DB message
      else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador ",
          "error"
        );
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
        return false;
      }
    }
  };

  // Function that removes an specific documment from the list
  removeDocumentsfromList = (i) => {
    let files = this.state.files;
    files.splice(i, 1);
    this.setState({ files });
  };

  // Function that handles event on the autit text area
  auditTextAreaChange = async ({ currentTarget: input }) => {
    let audit = { ...this.state.audit };
    audit.comments = input.value.trimStart();

    if (audit.comments.length > 500) {
      audit.error = `Supera por ${
        audit.comments.length - 500
      } el máximo de 500 caracteres permitidos.`;
    } else {
      audit.error = "";
    }
    this.setState({ audit });
  };

  // Function that creates the audit
  createAuditFunction = async () => {
    let userSession = { ...this.state.userSession };
    let audit = { ...this.state.audit };
    let canPost = true;
    let data = {
      comments: audit.comments,
      action: "UPDATE_BOND",
      idUser: userSession.userId,
    };

    // Blockif surpases comment max length
    if (audit.comments == "" || audit.comments.length > 500) {
      canPost = false;
    }
    if (canPost == true && audit.blocked == false) {
      //Block post button
      audit.blocked = true;
      this.setState({ audit });
      let response = await createAudit(
        data,
        audit.bondId,
        userSession.sessionToken
      );

      if (response.status == 200) {
        this.notify("Comentario guardado exitosamente", "success");
        this.closeAuditModal(); //Close modal and unlock button
      }
      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        audit.blocked = false;
        this.setState({ audit });
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        audit.blocked = false;
        this.setState({ audit });
      }
      //  Show expired session messagen and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
        // Unlock post button
        audit.blocked = false;
        this.setState({ audit });
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
        // Unlock post button
        audit.blocked = false;
        this.setState({ audit });
      }
    }
  };

  // Function that opens and loads the autid modal
  openAuditModal = async (bond) => {
    let userSession = { ...this.state.userSession };
    let audit = { ...this.state.audit };
    console.log(bond);
    audit.show = true;
    audit.bondId = bond.id;
    audit.number = bond.number;
    audit.actDate = bond.actDate ? bond.actDate : "";
    audit.actNum = bond.actNum ? bond.actNum : "";
    audit.awardType = bond.awardType ? bond.awardType.name : "";
    audit.bailExpire = bond.bailExpire ? bond.bailExpire : "";
    audit.bondAmount = bond.bondAmount ? bond.bondAmount : "";
    audit.client = bond.client ? bond.client : "";
    audit.contractObject = bond.contractObject ? bond.contractObject.name : "";
    audit.daysToExpire = bond.daysToExpire ? bond.daysToExpire : "";
    audit.defectsOrVices = bond.defectsOrVices ? bond.defectsOrVices : "";
    audit.endDate = bond.endDate ? bond.endDate : "";
    audit.endorsement = bond.endorsement ? bond.endorsement : "";
    audit.endorsementAmount = bond.endorsementAmount
      ? bond.endorsementAmount
      : "";
    audit.endorsementDate = bond.endorsementDate ? bond.endorsementDate : "";
    audit.endorsementDueDays = bond.endorsementDueDays
      ? bond.endorsementDueDays
      : "";
    audit.endorsementGuarantor = bond.endorsementGuarantor
      ? bond.endorsementGuarantor
      : "";
    audit.endorsementTime = bond.endorsementTime ? bond.endorsementTime : "";
    audit.executionPeriod = bond.executionPeriod ? bond.executionPeriod : "";
    audit.finalAct = bond.finalAct ? bond.finalAct : "";
    audit.finalDate = bond.finalDate ? bond.finalDate : "";
    audit.financialProgress = bond.financialProgress
      ? bond.financialProgress
      : "";
    audit.govEntity = bond.govEntity ? bond.govEntity.name : "";
    audit.guaranteeType = bond.guaranteeType
      ? bond.guaranteeType.name
      : "Propuesta";
    audit.insurance = bond.insuranceId ? bond.insuranceId.name : "";
    audit.orderDate = bond.orderDate ? bond.orderDate : "";
    audit.physicalProgress = bond.physicalProgress ? bond.physicalProgress : "";
    audit.procedureType = bond.procedureType ? bond.procedureType.name : "";
    audit.projectAmount = bond.projectAmount ? bond.projectAmount : "";
    audit.resolution = bond.regulatoryType ? bond.regulatoryType.name : "";
    audit.settlementPeriod = bond.settlementPeriod ? bond.settlementPeriod : "";
    audit.startDate = bond.startDate ? bond.startDate : "";
    audit.status = bond.status ? bond.status : "";
    audit.substantialDate = bond.substantialDate ? bond.substantialDate : "";
    audit.substantialRecord = bond.substantialRecord
      ? bond.substantialRecord
      : "";
    audit.totalSecured = bond.totalSecured ? bond.totalSecured : "";
    audit.userName = bond.userId ? bond.userId.name : "";
    audit.lastName = bond.userId ? bond.userId.lastName : "";
    audit.validityEndDate = bond.validityEndDate ? bond.validityEndDate : "";
    audit.updatedDate = bond.updatedDate ? bond.updatedDate : "";
    audit.actNum = bond.actNum ? bond.actNum : "";
    audit.issueDate = bond.issueDate ? bond.issueDate : "";
    audit.description = bond.description ? bond.description : "";
    audit.referencePrice = bond.referencePrice ? bond.referencePrice : "";
    audit.adjudicatedAmount = bond.adjudicatedAmount
      ? bond.adjudicatedAmount
      : "";
    audit.guarantorName = bond.guarantorName ? bond.guarantorName : "";
    this.setState({ audit }); // Open modal without the data
    let response = await getDocumentsByBondID(
      userSession.sessionToken,
      audit.bondId
    );
    // If there are documents left
    if (response.status == 200) {
      audit.documents = response.data;
    }
    // Show DB message
    else if (response.status == 400) {
      this.notify(response.data.errorMsg, "error");
    } else if (response.status == 401) {
      this.notify(response.data.errorMsg, "error");
    }
    //  Show expired session messagen and redirect to login
    else if (response.status == 403) {
      this.handleExpiredSession(history);
    } else {
      this.notify(
        "Ha ocurrido un error, por favor contacte al administador",
        "error"
      );
    }

    this.setState({ audit });
  };
  // Function that closes the audit modal
  closeAuditModal = async () => {
    let audit = { ...this.state.audit };
    audit.show = false;
    audit.comments = ""; // Delete comment from state
    audit.documents = ""; //Remove loaded documents from list
    audit.blocked = false; //Unlock the post button
    this.setState({ audit });
  };

  // Function that opens and loads the autid modal
  openCommentListModal = async (bond) => {
    let userSession = { ...this.state.userSession };
    let comments = { ...this.state.comments };
    comments.show = true;
    this.setState({ comments }); // Open modal without the data
    // Get the list of comments fom de audit
    let response = await getAuditedComments(userSession.sessionToken, bond.id);
    if (!response) {
      this.notify("Connection Error", "error");
      return null;
    }
    // If there are documents left
    if (response.status == 200) {
      comments.data = response.data;
    }
    // Show DB message
    else if (response.status == 400) {
      this.notify(response.data.errorMsg, "error");
    } else if (response.status == 401) {
      this.notify(response.data.errorMsg, "error");
    }
    //  Show expired session messagen and redirect to login
    else if (response.status == 403) {
      this.handleExpiredSession(history);
    } else {
      this.notify(
        "Ha ocurrido un error, por favor contacte al administador",
        "error"
      );
    }

    this.setState({ comments });
  };

  // Function that closes commment list modal
  closeCommentListModal = async () => {
    let comments = { ...this.state.comments };
    comments.show = false;
    comments.data = "";
    this.setState({ comments });
  };

  // Function that opens the base64document in another window
  openBase64File = (doc) => {
    // Open a new window with the PDF inside an iframe
    let pdfWindow = window.open();
    pdfWindow.document.write(
      "<html><head><title>" +
        `${doc.fileName}` +
        "</title></head><body height='100%' width='100%'><iframe width='100%' height='100%' src='" +
        encodeURI(doc.base64File) +
        "'></iframe></body>"
    );
  };

  // Function that loads the Bond data into the state for edition
  loadBondDataForEdition = async (bond) => {
    let bondForm = { ...this.state.bondForm };
    let userSession = { ...this.state.userSession };
    // Load Bond data in state for edition
    bondForm.data.id = bond.id;
    bondForm.data.client = bond.client;
    bondForm.data.number = bond.number;
    bondForm.data.bondAmount = bond.bondAmount;
    bondForm.data.projectAmount = bond.projectAmount;
    bondForm.data.startDate = new Date(
      moment.utc(bond.startDate).format("YYYY/MM/DD")
    );
    bondForm.data.endDate = new Date(
      moment.utc(bond.endDate).format("YYYY/MM/DD")
    );
    bondForm.data.actDate = bond.actDate
      ? new Date(moment.utc(bond.actDate).format("YYYY/MM/DD"))
      : "";
    bondForm.data.endorsementAmount = bond.endorsementAmount;
    bondForm.data.endorsementGuarantor = bond.endorsementGuarantor;
    bondForm.data.endorsement = bond.endorsement;
    bondForm.data.endorsementTime = bond.endorsementTime;
    bondForm.data.executionPeriod = bond.executionPeriod;
    bondForm.data.settlementPeriod = bond.settlementPeriod;
    bondForm.data.defectsOrVices = bond.defectsOrVices;
    bondForm.data.guaranteeType = bond.guaranteeType?.id;
    bondForm.data.resolution = bond.regulatoryType?.id;
    bondForm.data.govEntity = bond.govEntity?.id;
    bondForm.data.actNum = bond.actNum;
    bondForm.data.issueDate = bond.issueDate
      ? new Date(moment.utc(bond.issueDate).format("YYYY/MM/DD"))
      : "";
    bondForm.data.endorsementDate = bond.endorsementDate
      ? new Date(moment.utc(bond.endorsementDate).format("YYYY/MM/DD"))
      : "";
    this.setState({ bondForm });
    // Carga la lista de documentos
    let response = await getDocumentsByBondID(
      userSession.sessionToken,
      bond.id
    );

    if (!response) {
      this.notify("Connection Error", "error");
      return null;
    }

    // If there are documents left
    if (response.status == 200) {
      bondForm.data.files = response.data;
    }
    // Show DB message
    else if (response.status == 400) {
      this.notify(response.data.errorMsg, "error");
    } else if (response.status == 401) {
      this.notify(response.data.errorMsg, "error");
    }
    //  Show expired session messagen and redirect to login
    else if (response.status == 403) {
      this.notify("Su sesión ha expirado", "info");
      // this.handleExpiredSession(history);
    } else {
      this.notify(
        "Ha ocurrido un error, por favor contacte al administador",
        "error"
      );
    }
    this.setState({ bondForm });
  };

  // Function that updates the bond
  uptadeBondFunction = async (history) => {
    let bondForm = { ...this.state.bondForm };
    let userSession = { ...this.state.userSession };
    let gestion = { ...this.state.gestion };
    let files = this.state.files;

    typeof bondForm.data.projectAmount == "string"
      ? (bondForm.data.projectAmount = bondForm.data.projectAmount.replace(
          /,/g,
          ""
        ))
      : "";
    typeof bondForm.data.bondAmount == "string"
      ? (bondForm.data.bondAmount = bondForm.data.bondAmount.replace(/,/g, ""))
      : "";

    typeof bondForm.data.endorsementAmount == "string"
      ? (bondForm.data.endorsementAmount =
          bondForm.data.endorsementAmount.replace(/,/g, ""))
      : "";

    typeof bondForm.data.executionPeriod == "string"
      ? (bondForm.data.executionPeriod = bondForm.data.executionPeriod.replace(
          /,/g,
          ""
        ))
      : "";

    typeof bondForm.data.settlementPeriod == "string"
      ? (bondForm.data.settlementPeriod =
          bondForm.data.settlementPeriod.replace(/,/g, ""))
      : "";

    typeof bondForm.data.defectsOrVices == "string"
      ? (bondForm.data.defectsOrVices = bondForm.data.defectsOrVices.replace(
          /,/g,
          ""
        ))
      : "";

    typeof bondForm.data.endorsementTime == "string"
      ? (bondForm.data.endorsementTime = bondForm.data.endorsementTime.replace(
          /,/g,
          ""
        ))
      : "";

    // Validate file length before validating
    let canPostFile = this.validateIfExceedsWeight(files, 25);
    // Validate the user form inputs
    let canPost = this.validateBondForm();
    // IF valid parse int, filesize and Put ID
    if (canPost == true && canPostFile == true && bondForm.blocked == false) {
      // Block post button
      bondForm.blocked = true;
      this.setState({ bondForm });

      // Data to post to the API
      let dataToPost = {
        number: bondForm.data.number,
        client: bondForm.data.client,
        bondAmount: bondForm.data.bondAmount ? bondForm.data.bondAmount : null,
        projectAmount: null, // Now it is not necessary
        startDate: null, // Now it is not necessary
        endDate: null, // Now it is not necessary
        isPrivate: false,
        status: "PENDIENTE",
        userId: userSession.userId,
        actDate: bondForm.data.actDate
          ? bondForm.data.actDate.toISOString().slice(0, 10)
          : "", //Change to BD format
        govEntity: bondForm.data.govEntity ? bondForm.data.govEntity : null,
        description: "",
        contractObject: null,
        guaranteeType: bondForm.data.guaranteeType
          ? bondForm.data.guaranteeType
          : null,
        executionPeriod: bondForm.data.executionPeriod
          ? bondForm.data.executionPeriod
          : null,
        settlementPeriod: bondForm.data.settlementPeriod
          ? bondForm.data.settlementPeriod
          : null,
        orderDate: "",
        defectsOrVices: bondForm.data.defectsOrVices
          ? bondForm.data.defectsOrVices
          : null,
        bailExpire: "",
        daysToExpire: null,
        endorsement: bondForm.data.endorsement,
        endorsementTime: bondForm.data.endorsementTime
          ? bondForm.data.endorsementTime
          : null,
        endorsementAmount: bondForm.data.endorsementAmount
          ? bondForm.data.endorsementAmount
          : null,
        totalSecured: null,
        endorsementDate: bondForm.data.endorsementDate
          ? bondForm.data.endorsementDate.toISOString().slice(0, 10)
          : "",
        endorsementGuarantor: gestion.userInsurance[0].name,
        endorsementDueDays: null,
        physicalProgress: null,
        financialProgress: null,
        substantialRecord: "",
        substantialDate: "",
        finalAct: "",
        finalDate: "",
        regulatoryType: bondForm.data.resolution
          ? bondForm.data.resolution
          : null,
        actNum: bondForm.data.actNum ? bondForm.data.actNum : null,
        issueDate: bondForm.data.issueDate
          ? bondForm.data.issueDate.toISOString().slice(0, 10)
          : "",
        procedureType: null,
        awardType: null,
        // projectAmount: parseFloat(bondForm.data.projectAmount).toFixed(2),//DB requires float not string
        // bondAmount: parseFloat(bondForm.data.bondAmount).toFixed(2), //DB requires float not string
        // startDate: bondForm.data.startDate.toISOString().slice(0, 10), //Change to BD format
        // endDate: bondForm.data.endDate.toISOString().slice(0, 10), //Change to BD format
      };

      let response = await updateBondById(
        dataToPost,
        userSession.sessionToken,
        bondForm.data.id
      );

      // No response from API
      if (!response) {
        this.notify("Connection Error", "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      }

      // Bond created
      if (response.status == 200) {
        // Delete data from form inputs
        this.getMyBondsListFunction(
          userSession.sessionToken,
          userSession.userId
        ); //Get the bonds list from DB
        // Create document for old bonds
        if (files.length !== 0) {
          let documentCreated = await this.createDocument(
            bondForm.data.id,
            true
          );
          if (documentCreated == true) {
            this.notify("Fianza actualizada satisfactoriamente", "success");
          }
        } else {
          bondForm.blocked = false;
          this.notify("Fianza actualizada satisfactoriamente", "success");
        }
        history.push(`/gestion/consulta-fianza`);
      }
      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      }
      //  Show expired session message and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
        // Unlock post button
        bondForm.blocked = false;
        this.setState({ bondForm });
      }
    }
  };

  // Function that loads the User data into the state for edition
  loadUserDataForEdition = async (user) => {
    if (!user.govTypeId) user.govTypeId = null;

    if (!user.insuranceId) user.insuranceId = null;

    let userForm = { ...this.state.userForm };
    // Load User data in state for edition
    userForm.data.email = user.email;
    userForm.data.idGovType = user.govTypeId;
    userForm.data.id = user.id;
    userForm.data.idInsurance = user.insuranceId;
    userForm.data.name = user.name;
    userForm.data.phone = user.phoneNumber;
    userForm.data.lastName = user.lastName;
    userForm.data.idUserType = user.userTypeId;
    this.setState({ userForm });
  };

  editUserFunction = async (history) => {
    let userSession = { ...this.state.userSession };
    let userForm = { ...this.state.userForm };
    let data = {
      name: userForm.data.name,
      lastName: userForm.data.lastName,
      idInsurance: userForm.data.idInsurance,
      idUserType: userForm.data.idUserType,
      idGovType: userForm.data.idGovType,
      phone: userForm.data.phone,
    };

    // Validate the user form inputs
    let canPost = this.validateUserForm();
    if (canPost == true) {
      let response = await updateUser(
        data,
        userSession.sessionToken,
        userForm.data.id
      );

      // No response from API
      if (!response) {
        this.notify("Connection Error", "error");
        return null;
      }

      // User created
      if (response.status == 200) {
        this.notify("Usuario editado satisfactoriamente", "success");
        this.getUserListFunction(userSession.sessionToken); //Get the user list from DB
        // Delete data from form inputs
        this.restoreUserState();
        history.push(`/gestion/usuarios`);
      }
      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
      }
      //  Show expired session messagen and redirect to login
      else if (response.status == 403) {
        this.handleExpiredSession(history);
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
      }
    }
  };
  // Function that handles event changes on the register bond inputs
  consultBondByNumberInputChange = async ({ currentTarget: input }) => {
    let consultedBond = { ...this.state.consultedBond };
    // Trim input to avoid erros on the search
    consultedBond.data[input.name] = input.value.trimStart();
    this.setState({ consultedBond });
  };

  // Function that gets the bond by its number
  getBondByNumberFunction = async () => {
    let userSession = { ...this.state.userSession };
    let consultedBond = { ...this.state.consultedBond };
    // Block button
    let blockedLogin = true;
    this.setState({ blockedLogin });

    // Delete data of the previously loaded bond
    consultedBond.data.bond = "";
    consultedBond.data.files = "";
    this.setState({ consultedBond });

    let result = await getBondByNumber(
      userSession.sessionToken,
      consultedBond.data.bondNumber
    );

    // No response from API
    if (!result) {
      this.notify("Connection Error", "error");
      //  Unlock block button
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }

    // If the bond exist display its info and find its documments
    if (result.status == 200) {
      consultedBond.data.bond = result.data;
      this.setState({ consultedBond });
      let response = await getDocumentsByBondID(
        userSession.sessionToken,
        result.data.id
      );
      // If there are documents left
      if (response.status == 200) {
        consultedBond.data.files = response.data;
        this.setState({ consultedBond });
        //  Unlock block button
        blockedLogin = false;
        this.setState({ blockedLogin });
      }
      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
        //  Unlock block button
        blockedLogin = false;
        this.setState({ blockedLogin });
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
        //  Unlock block button
        blockedLogin = false;
        this.setState({ blockedLogin });
      }
      //  Show expired session messagen and redirect to login
      else if (response.status == 403) {
        this.notify("Su sesión ha expirado", "info");
        //  Unlock block button
        blockedLogin = false;
        this.setState({ blockedLogin });
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
        //  Unlock block button
        blockedLogin = false;
        this.setState({ blockedLogin });
      }
    }
    // Show DB message
    else if (result.status == 400) {
      this.notify(result.data.errorMsg, "error");
      //  Unlock block button
      blockedLogin = false;
      this.setState({ blockedLogin });
    } else if (result.status == 401) {
      this.notify(result.data.errorMsg, "error");
      //  Unlock block button
      blockedLogin = false;
      this.setState({ blockedLogin });
    }
    //  Show expired session messagen and redirect to login
    else {
      this.notify(
        "Ha ocurrido un error, por favor contacte al administador",
        "error"
      );
      //  Unlock block button
      blockedLogin = false;
      this.setState({ blockedLogin });
    }
  };

  // Function that validates the forgot password input
  validateForgotPwd = () => {
    let forgotPwd = { ...this.state.forgotPwdForm.data };
    let errors = { ...this.state.forgotPwdForm.errors };
    let canPost = true;

    if (empty(forgotPwd)) {
      errors.email = "Email requerido";
      canPost = false;
    }

    this.setState({ errors });
    return canPost;
  };

  // Function that validates the forgot password inputs
  passwordFormValidate = () => {
    const options = { abortEarly: false };

    const reset = {
      email: this.state.forgotPwdForm.data.email,
    };

    //Validation with Joi
    var schema = {
      email: Joi.string().required().label("email"),
    };

    const { error } = Joi.validate(reset, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  //Function that posts a password reset to the API
  forgotPasswordFunction = async () => {
    let forgotPwdForm = { ...this.state.forgotPwdForm };
    // Block button
    let blockedLogin = true;
    this.setState({ blockedLogin });

    // Validate the user form inputs
    let canPost = this.validateEmailFormat(forgotPwdForm.data.email);
    // Error message  in case the email format is wrong
    if (canPost === false) {
      toast.error(`Formato de correo invalido`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "Ts-op",
      });
      // Unlock send button
      forgotPwdForm.blocked = false;
      this.setState({ forgotPwdForm });
      // Remove load spinner
      blockedLogin = false;
      this.setState({ blockedLogin });
      return null;
    }

    if (canPost == true && forgotPwdForm.blocked === false) {
      // Block send button
      forgotPwdForm.blocked = true;
      this.setState({ forgotPwdForm });

      let body = {
        email: forgotPwdForm.data.email,
      };

      let response = await forgotPassword(body);

      // No response from API
      if (!response) {
        this.notify("Connection Error", "error");
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
        return null;
      }

      // password updated
      if (response.status == 200) {
        this.notify(
          "Se ha enviado una nueva contraseña a su correo",
          "success"
        );
        // Delete data from form inputs
        forgotPwdForm.data = { email: "" };
        forgotPwdForm.errors = { email: "" };
        this.setState({ forgotPwdForm });
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      }

      // Show DB message
      else if (response.status == 400) {
        this.notify(response.data.errorMsg, "error");
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      } else if (response.status == 401) {
        this.notify(response.data.errorMsg, "error");
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      } else {
        this.notify(
          "Ha ocurrido un error, por favor contacte al administador",
          "error"
        );
        // Unlock send button
        forgotPwdForm.blocked = false;
        this.setState({ forgotPwdForm });
        // Remove load spinner
        blockedLogin = false;
        this.setState({ blockedLogin });
      }
    }
  };

  // Function that handles event changes on the update password inputs
  passwordForgotinputChange = async ({ currentTarget: input }) => {
    let forgotPwdForm = { ...this.state.forgotPwdForm };

    // Trim input to avoid erros on the login
    forgotPwdForm.data[input.name] = input.value.trimStart();
    this.setState({ forgotPwdForm });
  };

  // Function that opens teh delete document modal
  openDeleteDocummentModal = (index) => {
    let bondForm = this.state.bondForm;
    // Close the delete modal
    bondForm.deleteModal = true;
    bondForm.deleteDocumentIndex = index;
    this.setState({ bondForm });
  };

  // Function that closes the delete document modal
  closeDeleteDocummentModal = () => {
    let bondForm = this.state.bondForm;
    // Close the delete modal
    bondForm.deleteModal = false;
    // Pass the selected document and index to the bondForm variable
    bondForm.deleteDocumentIndex = "";
    this.setState({ bondForm });
  };

  // Function that closes the delete document modal
  deleteDocummentFromDBFunction = async () => {
    let userSession = this.state.userSession;
    let bondForm = this.state.bondForm;
    // File id in DB
    let id = bondForm.data.files[bondForm.deleteDocumentIndex].id;
    // Close the delet document modal
    bondForm.deleteModal = false;
    // Pass the selected document and index to the bondForm variable
    this.setState({ bondForm });

    // Delete Document
    let response = await deleteDocumentService(userSession.sessionToken, id);

    if (!response) {
      this.notify("Connection Error", "error");
      return null;
    }
    // If there are documents left
    if (response.status == 200) {
      // Remove deleted file from state
      bondForm.data.files.splice(bondForm.deleteDocumentIndex, 1);
      this.setState({ bondForm });
      this.notify("Documento eliminado del sistema", "success");
    }
    // Show DB message
    else if (response.status == 400) {
      this.notify(response.data.errorMsg, "error");
    } else if (response.status == 401) {
      this.notify(response.data.errorMsg, "error");
    }
    //  Show expired session messagen and redirect to login
    else if (response.status == 403) {
      this.notify("Su sesión ha expirado", "info");
      // this.handleExpiredSession(history);
    } else {
      this.notify(
        "Ha ocurrido un error, por favor contacte al administador",
        "error"
      );
    }

    // Close the delete form modal and remove from state
    this.closeDeleteDocummentModal();
  };

  render() {
    const {
      user,
      errors,
      userSession,
      userList,
      gestion,
      userForm,
      bondForm,
      bondsList,
      files,
      audit,
      passwordUpdateForm,
      passwordShown,
      consultedBond,
      forgotPwdForm,
      comments,
      blockedLogin,
      loadList,
      reportForm,
    } = this.state;
    return (
      <div className="App">
        <Switch>
          <Route
            path="/login"
            render={(props) => {
              return (
                <Login
                  {...props}
                  props={this.state}
                  inputChange={this.inputChange}
                  loginService={this.loginService}
                  user={user}
                  errors={errors}
                  blockedLogin={blockedLogin}
                />
              );
            }}
          />
          <ProtectedRoute
            path="/registrar-fianza"
            component={Registro}
            level={["Aseguradora"]} // Tipos de usuario autorizados a esta ruta
            userSession={userSession}
            logoutService={this.logoutService}
            bondForm={bondForm}
            bondForminputChange={this.bondForminputChange}
            createBondFunction={this.createBondFunction}
            handleFromDateChange={this.handleFromDateChange}
            handleToDateChange={this.handleToDateChange}
            handleActDateChange={this.handleActDateChange}
            handleEndorsementDateChange={this.handleEndorsementDateChange}
            handleIssueDateChange={this.handleIssueDateChange}
            selectGuaranteeType={this.selectGuaranteeType}
            selectGovEntityType={this.selectGovEntityType}
            selectResolutionType={this.selectResolutionType}
            handleDragAndDropFiles={this.handleDragAndDropFiles}
            gestion={gestion}
            files={files}
            removeDocumentsfromList={this.removeDocumentsfromList}
            restoreAllForms={this.restoreAllForms}
          />

          <ProtectedRoute
            path="/editar-fianza"
            component={EditBondsPage}
            level={["Aseguradora"]} // Tipos de usuario autorizados a esta ruta
            userSession={userSession}
            logoutService={this.logoutService}
            bondForm={bondForm}
            bondForminputChange={this.bondForminputChange}
            handleFromDateChange={this.handleFromDateChange}
            handleToDateChange={this.handleToDateChange}
            handleActDateChange={this.handleActDateChange}
            handleEndorsementDateChange={this.handleEndorsementDateChange}
            handleIssueDateChange={this.handleIssueDateChange}
            selectGuaranteeType={this.selectGuaranteeType}
            selectGovEntityType={this.selectGovEntityType}
            selectResolutionType={this.selectResolutionType}
            handleDragAndDropFiles={this.handleDragAndDropFiles}
            gestion={gestion}
            files={files}
            removeDocumentsfromList={this.removeDocumentsfromList}
            restoreAllForms={this.restoreAllForms}
            uptadeBondFunction={this.uptadeBondFunction}
            openBase64File={this.openBase64File}
            openDeleteDocummentModal={this.openDeleteDocummentModal}
            closeDeleteDocummentModal={this.closeDeleteDocummentModal}
            deleteDocummentFromDBFunction={this.deleteDocummentFromDBFunction}
          />

          <ProtectedRoute
            path="/cambio-contraseña"
            level={["Administrador", "Aseguradora", "Gobierno"]} // Tipos de usuario autorizados a esta ruta
            component={ChangePasswordPage}
            userSession={userSession}
            logoutService={this.logoutService}
            passwordForminputChange={this.passwordForminputChange}
            setPasswordShownValue={this.setPasswordShownValue}
            updatePasswordFunction={this.updatePasswordFunction}
            restoreAllForms={this.restoreAllForms}
            passwordUpdateForm={passwordUpdateForm}
            passwordShown={passwordShown}
          />

          <ProtectedRoute
            path="/gestion"
            level={["Administrador", "Aseguradora", "Gobierno"]} // Tipos de usuario autorizados a esta ruta
            component={Menu}
            userList={userList}
            props={this.state}
            handleUserPageClick={this.handleUserPageClick}
            handleBondPageClick={this.handleBondPageClick}
            userSession={userSession}
            gestion={gestion}
            userForm={userForm}
            selectUserType={this.selectUserType}
            userForminputChange={this.userForminputChange}
            createUserFunction={this.createUserFunction} //Function that posts new users to the API
            logoutService={this.logoutService}
            searchUserBox={this.searchUserBox}
            bondsList={bondsList}
            searchBondsBox={this.searchBondsBox}
            restoreAllForms={this.restoreAllForms}
            passwordUpdateForm={passwordUpdateForm}
            passwordShown={passwordShown}
            passwordForminputChange={this.passwordForminputChange}
            updatePasswordFunction={this.updatePasswordFunction}
            setPasswordShownValue={this.setPasswordShownValue}
            openAuditModal={this.openAuditModal}
            closeAuditModal={this.closeAuditModal}
            audit={audit}
            auditTextAreaChange={this.auditTextAreaChange}
            createAuditFunction={this.createAuditFunction}
            openBase64File={this.openBase64File}
            loadBondDataForEdition={this.loadBondDataForEdition}
            loadUserDataForEdition={this.loadUserDataForEdition}
            editUserFunction={this.editUserFunction}
            comments={comments}
            closeCommentListModal={this.closeCommentListModal}
            openCommentListModal={this.openCommentListModal}
            loadList={loadList}
            handleReportStartDateChange={this.handleReportStartDateChange}
            handleReportEndDateChange={this.handleReportEndDateChange}
            reportForm={reportForm}
            reportFormInputChange={this.reportFormInputChange}
            selectReportInsurance={this.selectReportInsurance}
            selectReportGovEntity={this.selectReportGovEntity}
            selectReportGuaranteeType={this.selectReportGuaranteeType}
            reportFunction={this.reportFunction}
          />

          <Route
            path="/restaurar-contraseña"
            render={(props) => {
              return (
                <ForgottPassword
                  {...props}
                  props={this.state}
                  passwordForgotinputChange={this.passwordForgotinputChange}
                  forgotPasswordFunction={this.forgotPasswordFunction}
                  forgotPwdForm={forgotPwdForm}
                  blockedLogin={blockedLogin}
                />
              );
            }}
          />

          <Route
            path="/consulta"
            render={(props) => {
              return (
                <Home
                  {...props}
                  props={this.state}
                  consultedBond={consultedBond}
                  consultBondByNumberInputChange={
                    this.consultBondByNumberInputChange
                  }
                  getBondByNumberFunction={this.getBondByNumberFunction}
                  openBase64File={this.openBase64File}
                  blockedLogin={blockedLogin}
                  getPublicGovBondsListFunction={
                    this.getPublicGovBondsListFunction
                  }
                />
              );
            }}
          />

          {/* <Route 
            path="/fianzas"
            render={(props) => {
              return (
                <Bond
                  {...props}
                  props={this.state}
                  bondsList={bondsList}
                  handleBondPageClick={this.handleBondPageClick}
                  searchBondsBox={this.searchBondsBox}
                  openAuditModal={this.openAuditModal}
                  closeAuditModal={this.closeAuditModal}
                  audit={audit}
                  auditTextAreaChange={this.auditTextAreaChange}
                  createAuditFunction={this.createAuditFunction}
                  openBase64File={this.openBase64File}
                  comments={comments}
                  closeCommentListModal={this.closeCommentListModal}
                  openCommentListModal={this.openCommentListModal}
                  loadList={loadList}
                  getPublicGovBondsListFunction={this.getPublicGovBondsListFunction}
                />
              )
            }}
          /> */}

          {/* Ruta por defecto redirecciona si la ruta es / */}
          <Route exact path="/">
            <Redirect to="/consulta" />
          </Route>

          {/* Redireccionar a /consulta si la ruta no existe */}
          <Route
            render={(props) => {
              return (
                <Route>
                  <Redirect props={props} to="/consulta" />
                </Route>
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}
export default App;
