import React from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../../images/logo-transparent.png";
import cambioClave from "../../../images/cambio-clave.png";
import gestionUsuarios from "../../../images/gestion-usuarios.png";
import registrarFianzas from "../../../images/registro-fianzas.png";
import fianzas from "../../../images/fianzas.png";
import "./Sidebar.scss";



// Link to Change Password dashbboard 
export const ChangePasswordOption = (restoreAllForms) => {
  return (
    <NavLink to="/cambio-contraseña">
    <div onClick={restoreAllForms} className="menu-option">
      <img src={cambioClave} alt=""/>
      <h6>Cambio de Clave</h6>
    </div>
  </NavLink>
  )
}


// Link to Gestión de usuarios dashbboard 
export const UserManagementOption = (restoreAllForms) => {
  return (
    <NavLink to="/gestion/registro">
      <div onClick={restoreAllForms} className="menu-option">
       <img src={gestionUsuarios} alt=""/>
       <h6>Gestión de usuarios</h6>
      </div>
    </NavLink>
  )
}

// Link to User list option in the dashbboard 
export const UserListOption = (restoreAllForms) => {
  return (
    <NavLink to="/gestion/usuarios">
      <div onClick={restoreAllForms} className="menu-option">
       <img src={gestionUsuarios} alt=""/>
       <h6>Lista de usuarios</h6>
      </div>
    </NavLink>
  )
}


// Link to Fianzas 
export const FianzasOption = (restoreAllForms) => {
  return (
    <NavLink to="/gestion/fianzas">
     <div onClick={restoreAllForms} className="menu-option">
         <img src={fianzas} alt=""/>
         <h6>Fianzas</h6>
     </div>
  </NavLink>
  )
}

// Link to Fianzas 
export const RegisterFianzasOption = (restoreAllForms) => {
  return (
    <NavLink to="/registrar-fianza">
     <div onClick={restoreAllForms} className="menu-option">
         <img src={registrarFianzas} alt=""/>
         <h6>Registrar fianza</h6>
     </div>
  </NavLink>
  )
}

// Link to Consutla de fianzas 
export const ConsultMyFianzasOption = (restoreAllForms) => {
  return (
    <NavLink to="/gestion/consulta-fianza">
     <div onClick={restoreAllForms} className="menu-option">
         <img src={fianzas} alt=""/>
         <h6>Mis fianzas</h6>
     </div>
  </NavLink>
  )
}

// Link to Fianzas 
export const ReportOption = (restoreAllForms) => {
  return (
    <NavLink to="/gestion/reporte">
     <div onClick={restoreAllForms} className="menu-option">
         <img src={registrarFianzas} alt=""/>
         <h6>Reporte</h6>
     </div>
  </NavLink>
  )
}

// Menu sidebar component
export const Sidebar = (props) => {

  const {userSession, restoreAllForms} = props;

  // Admin Profile Navlinks
  if (userSession.userType === "Administrador" ){
    return(
      <div className="menu-sidebar">
        <img className="menu-logo" src={logo} alt=""/>
        {UserManagementOption(restoreAllForms)}
        {UserListOption(restoreAllForms)}
        {ChangePasswordOption(restoreAllForms)}
        {ReportOption(restoreAllForms)}
      </div>
    )
  }
  // Insurance Profile Navlinks
  if (userSession.userType === "Aseguradora" ){
    return(
      <div className="menu-sidebar">
        <img className="menu-logo" src={logo} alt=""/>
        {RegisterFianzasOption(restoreAllForms)}
        {ConsultMyFianzasOption(restoreAllForms)}
        {ChangePasswordOption(restoreAllForms)}
        {ReportOption(restoreAllForms)}
      </div>
     )
  }
  // Government Profile Navlinks
  if (userSession.userType === "Gobierno" ){
    return(
     <div className="menu-sidebar">
      <img className="menu-logo" src={logo} alt=""/>
      {FianzasOption(restoreAllForms)}
      {ChangePasswordOption(restoreAllForms)}
      {ReportOption(restoreAllForms)}
    </div>
    )
  }else {
    return null;
  }

}
