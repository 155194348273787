import React from 'react';
import {SaveUserButton, EditUserButton, SavePasswordButton} from "../Buttons/Buttons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, {registerLocale} from 'react-datepicker';
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import "./Forms.scss";
import NumberFormat from 'react-number-format';

// For translating date pickers
registerLocale("es", es);


export const RecoverPasswordInput = (props) => {

  const {passwordForgotinputChange, forgotPwdForm} = props;

  return (
    
    <div className="recover-password-input-cotainer">
        <div>
          <label  className="input-title">Indique el correo con el que está registrado</label>
          <input 
               onChange={passwordForgotinputChange}
               autoComplete="off"
               className="input" 
               type="text" 
               name="email" 
               placeholder="correo"
               value={forgotPwdForm.data.email}/>    
         </div>       
         <ErrorAlert error={forgotPwdForm.errors.email} />
      </div>

  )

}


// Form that changes password 
export const PasswordChangeForm = (props) => {

  const {passwordForminputChange, passwordShown, setPasswordShownValue, 
    updatePasswordFunction,history, passwordUpdateForm} = props;

  return (

    <div className="row">
      <div className="register-container col-12 col-sm-8 col-md-6 col-lg-4">
        <div>
          <div className="row">
            <div className="register-input-container col-12">
              <label className="input-title">Clave actual</label>
              <div className="input-eye-container">
                <input 
                  onChange={passwordForminputChange}
                  autoComplete="off" 
                  className="input"
                  type={passwordShown === true ? "text" : "password"}
                  name="oldPassword"
                  placeholder="Clave actual"
                  value={passwordUpdateForm.data.oldPassword}
                />
                <i id="eyePassword" className="font-eye" onClick={()=>setPasswordShownValue()}>
                  {passwordShown === false ? <FontAwesomeIcon icon='eye'/> : <FontAwesomeIcon icon='eye-slash'/>}
                </i>
              </div>

              <ErrorAlert error={passwordUpdateForm.errors.oldPassword} />
            </div>

            <div className="register-input-container col-12">
              <label className="input-title">Clave nueva</label>
              <div className="input-eye-container">
                <input
                  onChange={passwordForminputChange}
                  autoComplete="off" 
                  className="input"
                  type={passwordShown === true ? "text" : "password"}
                  name="newPassword"
                  placeholder="Clave nueva"
                  value={passwordUpdateForm.data.newPassword}
                />
                <i id="eyePassword" className="font-eye" onClick={()=>setPasswordShownValue()}>
                  {passwordShown === false ? <FontAwesomeIcon icon='eye'/> : <FontAwesomeIcon icon='eye-slash'/>}
                </i>
              </div>
              <ErrorAlert error={passwordUpdateForm.errors.newPassword} />
            </div>

            <div className="register-input-container col-12">
              <label className="input-title">Confirmar clave nueva</label>
              <div className="input-eye-container">
                <input
                  onChange={passwordForminputChange}
                  autoComplete="off" 
                  className="input"
                  type={passwordShown ? "text" : "password"}
                  name="confirmNewPassword"
                  placeholder="Confirmar clave nueva"
                  value={passwordUpdateForm.data.confirmNewPassword}
                />
                <i id="eyePassword" className="font-eye" onClick={()=>setPasswordShownValue()}>
                  {passwordShown === false ? <FontAwesomeIcon icon='eye'/> : <FontAwesomeIcon icon='eye-slash'/>}
                </i>
              </div>

              <ErrorAlert error={passwordUpdateForm.errors.confirmNewPassword} />
            </div>

            <div className="col-12 save-button-contanier">
              <SavePasswordButton
               updatePasswordFunction={updatePasswordFunction}
               history={history}
               passwordUpdateForm={passwordUpdateForm}
              />
              {/* {savePasswordButton(updatePasswordFunction, history, passwordUpdateForm)} */}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

// Input that selects the user type
export const SelectUserTypeInput = (props) => {

  const {selectUserType,userForm,gestion} = props; 

  return (
    <div className="register-input-container col-12 col-md-6">
      <label  className="input-title">Seleccione el tipo de usuario</label>
      <select  onChange={selectUserType} name="idUserType" value={userForm.data.idUserType} >
      <option className="option-place-holder" value="">Seleccionar</option>
      {gestion.userType.map(u => <option key={u.id} value={u.id}>{u.name}</option> )}
       </select>
      <ErrorAlert error={""} />
    </div>
  )
}

// Input that selects the government type
export const SelectGovernType = (props) => {

  const {userForm, gestion, selectUserType} = props; 

  // Do not display selector
  if (!userForm.data.idUserType || userForm.data.idUserType === 1 || userForm.data.idUserType === 2) {
    return null;
  }

  // Government type of entity selector
  if (userForm.data.idUserType === 3) {
    return (
      <div className="register-input-container col-12">
        <label  className="input-title">Seleccione una entidad gubernamental</label>
        <select onChange={selectUserType} name="idGovType" value={userForm.data.idGovType} >
          <option  className="option-place-holder" value={null}>Seleccionar</option>
          {gestion.govEntitiesType.map(u => <option key={u.id} value={u.id}>{u.name}</option> )}
        </select>
        <ErrorAlert error={userForm.errors.idGovType} />
      </div>
    )  
  }
}

export const SelectInsuranceType = (props) => {
  
  const {userForm, gestion, selectUserType} = props;

  // Do not display selector
  if (!userForm.data.idUserType || userForm.data.idUserType === 1 || userForm.data.idUserType === 3) {
    return null;
  }
  // Insurance type of entity selector
  if (userForm.data.idUserType === 2) {
    return (
      <div className="register-input-container col-12 col-md-6">
        <label  className="input-title">Seleccione una de las aseguradoras</label>
        <select onChange={selectUserType} name="idInsurance" value={userForm.data.idInsurance}  >
          <option  className="option-place-holder" value={null} >Seleccionar</option>
          {gestion.insurance.map(u => <option key={u.id} value={u.id}>{u.name}</option> )}
        </select>
        <ErrorAlert error={userForm.errors.idInsurance} />
      </div>
    )
  }
}

// Edit user form

export const EditUserForm = (props) => {
  const {userForm, userForminputChange, editUserFunction, history} = props;

  if (!userForm.data.idUserType ) {
    return null;
  }
  
  
  if (userForm.data.idUserType !== "") {
    return (
      <React.Fragment>
        <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Nombre</label>
                  <input
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  value={userForm.data.name}
                  />
                <ErrorAlert error={userForm.errors.name} />
                </div>

    
                <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Apellido</label>
                  <input 
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="lastName"
                  placeholder="Apellido"
                  value={userForm.data.lastName}
                  />
                 <ErrorAlert error={userForm.errors.lastName} />
                </div>
    
                <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Email (Usuario de la aplicación)</label>
                  <input 
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input blocked-input"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={userForm.data.email}
                  disabled
                  />
                  <ErrorAlert error={userForm.errors.email} />
                </div>
    
                <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Teléfono</label>
                  <input 
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="phone"
                  placeholder="Teléfono"
                  value={userForm.data.phone}
                  />
                 <ErrorAlert error={userForm.errors.phone} />
                </div>
    
                
    
                <div className="col-12 save-button-contanier">
                  <EditUserButton
                  userForm={userForm}
                  editUserFunction={editUserFunction}
                  history={history}
                  />
                </div>
      </React.Fragment>
  )
  }
}



// Form for creating users 
export const CreateUserForm = (props) => {
  const {userForm, userForminputChange, createUserFunction, history} = props;

  if (userForm.data.idUserType === "" || userForm.data.idUserType === 0 ) {
    return null;
  }
  
  
  if (userForm.data.idUserType !== "") {
    return (
      <React.Fragment>
        <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Nombre</label>
                  <input
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  value={userForm.data.name}
                  />
                <ErrorAlert error={userForm.errors.name} />
                </div>

    
                <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Apellido</label>
                  <input 
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="lastName"
                  placeholder="Apellido"
                  value={userForm.data.lastName}
                  />
                 <ErrorAlert error={userForm.errors.lastName} />
                </div>
    
                <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Email (Usuario de la aplicación)</label>
                  <input 
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={userForm.data.email}
                  />
                  <ErrorAlert error={userForm.errors.email} />
                </div>
    
                <div className="register-input-container col-12 col-md-6">
                  <label  className="input-title">Teléfono</label>
                  <input 
                  onChange={userForminputChange}
                  autoComplete="off" 
                  className="input"
                  type="text"
                  name="phone"
                  placeholder="Teléfono"
                  value={userForm.data.phone}
                  />
                 <ErrorAlert error={userForm.errors.phone} />
                </div>
    
                
    
                <div className="col-12 save-button-contanier">
                  <SaveUserButton
                  userForm={userForm}
                  createUserFunction={createUserFunction}
                  history={history}
                  />
                </div>
      </React.Fragment>
  )
  }
}
// Form that consults the bond by its number 
export const ConsultBondByNumberForm = (props) => {

  const {consultedBond,  consultBondByNumberInputChange}= props;


  return (
    <React.Fragment>
    <div>
      <label  className="input-title">Indique el número de fianza</label>
      <input         
      onChange={consultBondByNumberInputChange}
      autoComplete="off" 
      className="input"
      type="text"
      name="bondNumber"
      placeholder="Indique el número de fianza"
      value={consultedBond.data.bondNumber}
      />
    </div>
      <ErrorAlert error={consultedBond.errors.bondNumber} />
    </React.Fragment>
  )
}


// Form that edits the bond 
export const EditBondForm = (props) => {

  const {
    bondForm, 
    bondForminputChange, 
    handleActDateChange, 
    selectGuaranteeType, 
    selectGovEntityType, 
    selectResolutionType, 
    handleEndorsementDateChange,
    handleIssueDateChange,
    gestion } = props;

  return (
    <React.Fragment>
      <SelecGuaranteeTypeInput
        selectGuaranteeType={selectGuaranteeType}
        bondForm={bondForm}
        gestion={gestion}
        isEdit={true}
      />

      <div className="register-input-container col-11 col-md-5">
        <label  className="input-title">Cliente</label>
        <input 
        onChange={bondForminputChange}
        autoComplete="off" 
        className="input blocked-input"
        type="text"
        name="client"
        placeholder="Cliente"
        value={bondForm.data.client}
        disabled
        />
        <ErrorAlert error={bondForm.errors.client} />
      </div>

      <div className="register-input-container col-11 col-md-5">
        <label  className="input-title">Número de fianza</label>
        <input         
        onChange={bondForminputChange}
        autoComplete="off" 
        className="input blocked-input"
        type="text"
        name="number"
        placeholder="Indique su número de fianza"
        value={bondForm.data.number}
        disabled
        />
        <ErrorAlert error={bondForm.errors.number} />
      </div>

      {bondForm.data.guaranteeType !== 2 && (
        <div className="register-input-date-container col-11 col-md-5">
          <div>
            <div className="register-input-container register-input-date-mb" >
              <label  className="input-title">Fecha de acto</label>
              <DatePicker onChange={handleActDateChange} selected={bondForm.data.actDate}  locale="es" dateFormat='dd/MM/yyyy'/>
            </div>
          </div>
            <ErrorAlert error={bondForm.errors.actDate} />
        </div>
      )}


      {bondForm.data.guaranteeType === 2 && (
        <>
          <div className="register-input-container col-11 col-md-5">
            <label  className="input-title">Número de acto público</label>
            <input 
              onChange={bondForminputChange}
              autoComplete="off" 
              className="input"
              type="text"
              name="actNum"
              placeholder="Número de acto público"
              value={bondForm.data.actNum}
            />
            <ErrorAlert error={bondForm.errors.actNum} />
          </div>

          <div className="register-input-date-container col-11 col-md-5">
            <div>
              <div className="register-input-container register-input-date-mb" >
                <label  className="input-title">Fecha de emisión</label>
                <DatePicker  onChange={handleIssueDateChange} selected={bondForm.data.issueDate}  locale="es" dateFormat='dd/MM/yyyy'/>
              </div>
            </div>
            <ErrorAlert error={bondForm.errors.issueDate} />
          </div>

          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Monto fianza</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="bondAmount" 
              className="input"
              placeholder="Monto fianza"
              value={bondForm.data.bondAmount} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.bondAmount} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Periodo de ejecución (días)</label>
            <div className="pt-none pt-md-3">
              <NumberFormat 
                onChange={bondForminputChange} 
                name="executionPeriod" 
                className="input"
                placeholder="Periodo de ejecución (días)"
                value={bondForm.data.executionPeriod} 
                thousandSeparator={true} 
                decimalSeparator="."/>
            </div>
            <ErrorAlert error={bondForm.errors.executionPeriod} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Periodo de liquidación (días) - Suman al periodo de ejecución para llegar a la vigencia</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="settlementPeriod" 
              className="input"
              placeholder="Periodo de liquidación (días)"
              value={bondForm.data.settlementPeriod} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.settlementPeriod} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Periodo de vigencia defectos o vicios</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="defectsOrVices" 
              className="input"
              placeholder="Periodo de vigencia defectos o vicios"
              value={bondForm.data.defectsOrVices} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.defectsOrVices} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label  className="input-title">Endoso</label>
            <input         
              onChange={bondForminputChange}
              autoComplete="off" 
              className="input"
              type="text"
              name="endorsement"
              placeholder="Endoso"
              value={bondForm.data.endorsement}
            />
            <ErrorAlert error={bondForm.errors.endorsement} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Endoso tiempo (días)</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="endorsementTime" 
              className="input"
              placeholder="Endoso tiempo (días)"
              value={bondForm.data.endorsementTime} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.endorsementTime} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Monto endoso</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="endorsementAmount" 
              className="input"
              placeholder="Monto endoso"
              value={bondForm.data.endorsementAmount} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.endorsementAmount} />
          </div>  
          
          <div className="register-input-container col-11 col-md-5">
            <label  className="input-title">Nombre del fiador del endoso</label>
            <input 
              onChange={bondForminputChange}
              autoComplete="off" 
              className="input"
              type="text"
              name="endorsementGuarantor"
              placeholder="Nombre del fiador"
              value={bondForm.data.endorsementGuarantor}
              disabled
            />
            <ErrorAlert error={bondForm.errors.endorsementGuarantor} />
          </div>
    
          <div className="register-input-date-container col-11 col-md-5">
            <div>
              <div className="register-input-container register-input-date-mb" >
                <label  className="input-title">Fecha de emisión del endoso</label>
                <DatePicker  onChange={handleEndorsementDateChange} selected={bondForm.data.endorsementDate}  locale="es" dateFormat='dd/MM/yyyy'/>
              </div>
            </div>
            <ErrorAlert error={bondForm.errors.endorsementDate} />
          </div>
    
          <SelecResolutionTypeInput
            selectResolutionType={selectResolutionType}
            bondForm={bondForm}
            gestion={gestion}
          />
    
          <SelecGovEntityTypeInput
            selectGovEntityType={selectGovEntityType}
            bondForm={bondForm}
            gestion={gestion}
          />
        </>
      )}
    </React.Fragment>
    )
}

// Input that selects the guarantee type
export const SelecGuaranteeTypeInput = (props) => {

  const {selectGuaranteeType, bondForm, gestion, isEdit} = props; 

  return (
    <div className="register-input-container col-11 col-md-5">
      <label  className="input-title">Seleccione el tipo de garantía</label>
      <select  onChange={selectGuaranteeType} name="guaranteeType" value={bondForm.data.guaranteeType} disabled={isEdit}>
      <option className="option-place-holder" value="">Seleccionar</option>
      {gestion && gestion.guaranteeType.map(u => <option key={u.id} value={u.id}>{u.name}</option> )}
       </select>
      <ErrorAlert error={""} />
    </div>
  )
}

// Input that selects the goverment entity type
export const SelecGovEntityTypeInput = (props) => {

  const {selectGovEntityType, bondForm, gestion} = props; 

  return (
    <div className="register-input-container col-11 col-md-10">
      <label  className="input-title">Seleccione la entidad gubernamental</label>
      <select  onChange={selectGovEntityType} name="govEntity" value={bondForm.data.govEntity} >
      <option className="option-place-holder" value="">Seleccionar</option>
      {gestion && gestion.govEntitiesType.map(u => <option key={u.id} value={u.id}>{u.name}</option> )}
       </select>
      <ErrorAlert error={""} />
    </div>
  )
}

// Input that selects the resolution type
export const SelecResolutionTypeInput = (props) => {

  const {selectResolutionType, bondForm, gestion} = props; 

  return (
    <div className="register-input-container col-11 col-md-10">
      <label  className="input-title">Seleccione el tipo de resolucion</label>
      <select  onChange={selectResolutionType} name="resolution" value={bondForm.data.resolution} >
      <option className="option-place-holder" value="">Seleccionar</option>
      {gestion && gestion.resolutionsType.map(u => <option key={u.id} value={u.id}>{u.name}</option> )}
       </select>
      <ErrorAlert error={""} />
    </div>
  )
}

// Form that edits the bond 
export const CreateBondForm = (props) => {

  const {
    bondForm, 
    bondForminputChange, 
    handleActDateChange, 
    selectGuaranteeType, 
    selectGovEntityType, 
    selectResolutionType, 
    handleEndorsementDateChange,
    handleIssueDateChange,
    gestion
  } = props;

  return (
    <React.Fragment>
      <SelecGuaranteeTypeInput
        selectGuaranteeType={selectGuaranteeType}
        bondForm={bondForm}
        gestion={gestion}
        isEdit={false}
      />

      <div className="register-input-container col-11 col-md-5">
        <label  className="input-title">Cliente</label>
        <input 
          onChange={bondForminputChange}
          autoComplete="off" 
          className="input"
          type="text"
          name="client"
          placeholder="Cliente"
          value={bondForm.data.client}
        />
        <ErrorAlert error={bondForm.errors.client} />
      </div>

      <div className="register-input-container col-11 col-md-5">
        <label  className="input-title">Número de fianza</label>
        <input         
          onChange={bondForminputChange}
          autoComplete="off" 
          className="input"
          type="text"
          name="number"
          placeholder="Indique su número de fianza"
          value={bondForm.data.number}
        />
        <ErrorAlert error={bondForm.errors.number} />
      </div>

      {bondForm.data.guaranteeType !== 2 && (
        <div className="register-input-date-container col-11 col-md-5">
          <div>
            <div className="register-input-container register-input-date-mb" >
              <label  className="input-title">Fecha de acto</label>
              <DatePicker  onChange={handleActDateChange} selected={bondForm.data.actDate}  locale="es" dateFormat='dd/MM/yyyy'/>
            </div>
          </div>
          <ErrorAlert error={bondForm.errors.actDate} />
        </div>
      )}

      {bondForm.data.guaranteeType === 2 && (
        <>
          <div className="register-input-container col-11 col-md-5">
            <label  className="input-title">Número de acto público</label>
            <input 
              onChange={bondForminputChange}
              autoComplete="off" 
              className="input"
              type="text"
              name="actNum"
              placeholder="Número de acto público"
              value={bondForm.data.actNum}
            />
            <ErrorAlert error={bondForm.errors.actNum} />
          </div>

          <div className="register-input-date-container col-11 col-md-5">
            <div>
              <div className="register-input-container register-input-date-mb" >
                <label  className="input-title">Fecha de emisión</label>
                <DatePicker  onChange={handleIssueDateChange} selected={bondForm.data.issueDate}  locale="es" dateFormat='dd/MM/yyyy'/>
              </div>
            </div>
            <ErrorAlert error={bondForm.errors.issueDate} />
          </div>

          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Monto fianza</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="bondAmount" 
              className="input"
              placeholder="Monto fianza"
              value={bondForm.data.bondAmount} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.bondAmount} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Periodo de ejecución (días)</label>
            <div className="pt-none pt-md-3">
              <NumberFormat 
                onChange={bondForminputChange} 
                name="executionPeriod"
                className="input"
                placeholder="Periodo de ejecución (días)"
                value={bondForm.data.executionPeriod} 
                thousandSeparator={true} 
                decimalSeparator="."/>
            </div>
            <ErrorAlert error={bondForm.errors.executionPeriod} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Periodo de liquidación (días) - Suman al periodo de ejecución para llegar a la vigencia</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="settlementPeriod" 
              className="input"
              placeholder="Periodo de liquidación (días)"
              value={bondForm.data.settlementPeriod} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.settlementPeriod} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Periodo de vigencia defectos o vicios</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="defectsOrVices" 
              className="input"
              placeholder="Periodo de vigencia defectos o vicios"
              value={bondForm.data.defectsOrVices} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.defectsOrVices} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label  className="input-title">Endoso</label>
            <input         
              onChange={bondForminputChange}
              autoComplete="off" 
              className="input"
              type="text"
              name="endorsement"
              placeholder="Endoso"
              value={bondForm.data.endorsement}
            />
            <ErrorAlert error={bondForm.errors.endorsement} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Endoso tiempo (días)</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="endorsementTime" 
              className="input"
              placeholder="Endoso tiempo (días)"
              value={bondForm.data.endorsementTime} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.endorsementTime} />
          </div>
    
          <div className="register-input-container col-11 col-md-5">
            <label className="input-title">Monto endoso</label>
            <NumberFormat 
              onChange={bondForminputChange} 
              name="endorsementAmount" 
              className="input"
              placeholder="Monto endoso"
              value={bondForm.data.endorsementAmount} 
              thousandSeparator={true} 
              decimalSeparator="."/>
            <ErrorAlert error={bondForm.errors.endorsementAmount} />
          </div>  
          
          <div className="register-input-container col-11 col-md-5">
            <label  className="input-title">Nombre del fiador del endoso</label>
            <input 
              onChange={bondForminputChange}
              autoComplete="off" 
              className="input"
              type="text"
              name="endorsementGuarantor"
              placeholder="Nombre del fiador"
              value={gestion.userInsurance.map(insurance => insurance.name)}
              disabled
            />
          </div>
    
          <div className="register-input-date-container col-11 col-md-5">
            <div>
              <div className="register-input-container register-input-date-mb" >
                <label  className="input-title">Fecha de emisión del endoso</label>
                <DatePicker  onChange={handleEndorsementDateChange} selected={bondForm.data.endorsementDate}  locale="es" dateFormat='dd/MM/yyyy'/>
              </div>
            </div>
            <ErrorAlert error={bondForm.errors.endorsementDate} />
          </div>
    
          <SelecResolutionTypeInput
            selectResolutionType={selectResolutionType}
            bondForm={bondForm}
            gestion={gestion}
          />
    
          <SelecGovEntityTypeInput
            selectGovEntityType={selectGovEntityType}
            bondForm={bondForm}
            gestion={gestion}
          />
        </>
      )}
    </React.Fragment>
    )
}