import React from 'react';
import Header from "../common/Header/Header";
import {ConsultBondByIdTable} from "../common/Tables/Tables";
import {DownloadedFilesList} from "../common/DocElements/DocElements";
import {LoadSpinner} from "../common/LoadSpinner/LoadSpinner";
import {ConsultBondByNumberForm} from "../common/Forms/Forms";
import {
  ConsultBondByIdNumberButton, 
  // ShowListBonds
} from "../common/Buttons/Buttons";
import homeBackground from "../../images/home-background.png";
import "./Home.scss";


export const BondDocumetns = (consultedBond,openBase64File) => {

  if (consultedBond.data.bond !== ""){
    return (
      <React.Fragment>
          <h1 className="title">Documentación</h1>
          <div className="accepted-files-conainer">
            <DownloadedFilesList
            documents={consultedBond.data.files} 
            openBase64File={openBase64File}       
            />
          </div>
      </React.Fragment>
    )
  }else {
    return null;
  }  
}


export const Home = (props) => {

  const {consultedBond,  consultBondByNumberInputChange, getBondByNumberFunction, openBase64File, blockedLogin, 
    // getPublicGovBondsListFunction
  }= props;

  return (
    <React.Fragment>

    <LoadSpinner blocked={blockedLogin} text="Buscando fianza" color="grey"/>

     <Header/>
    <div  className="home-container row">
      <div className="col-12 col-md-7" >
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between gap-5">
            <h1 className="title">Bienvenido a su consulta de fianzas</h1>
            {/* <ShowListBonds getPublicGovBondsListFunction={getPublicGovBondsListFunction}/> */}
          </div>
        </div>
        <div className="finanzas-search-container">

          <ConsultBondByNumberForm
          consultedBond={consultedBond}
          consultBondByNumberInputChange={consultBondByNumberInputChange}
          />

          <ConsultBondByIdNumberButton
          bondNumber={consultedBond.data.bondNumber}
          getBondByNumberFunction={getBondByNumberFunction}
          />
        </div>
        
        <ConsultBondByIdTable
          bond={consultedBond.data.bond}
        />

      {BondDocumetns(consultedBond, openBase64File)}

      </div>
      {/* <div> */}
        <div id="home-slogan-container" className="slogan-container col-md-5 ">
          <h1 >Trabajamos para <br/> ofrecerle el mejor <br/> servicio</h1>
          <img src={homeBackground} alt="img"/>
        </div>
      {/* </div> */}
    </div>
    </React.Fragment>
    );
};