import React from 'react';
import {SelectUserTypeInput, SelectGovernType, SelectInsuranceType, CreateUserForm, EditUserForm} from "../../../common/Forms/Forms";
import {NavLink} from "react-router-dom";
import trabajando from "../../../../images/trabajando.png";
import arrow from  "../../../../images/arrow.png";

import "./Usuarios.scss";



// Component thet edits  users
export const EditUser = (props) => {

  const {gestion, selectUserType, userForm, userForminputChange, history, editUserFunction} = props;

  if (!gestion.userType ||  !gestion.govType ) {
   return null;
  }else {
    return (  

      <div className="row">
        <div className="register-container col-12 col-lg-8">
          <div>
            <div className="register-img-container">
              <img src={trabajando} alt=""/>
              <h6>Estamos trabajando para ti</h6>
            </div>
  
            <div className="row">
              
              <SelectUserTypeInput
              selectUserType={selectUserType}
              userForm={userForm}
              gestion={gestion}
              />

              <SelectGovernType 
              userForm={userForm}
              gestion={gestion}
              selectUserType={selectUserType}
              />
              
              <SelectInsuranceType
              userForm={userForm}
              gestion={gestion}
              selectUserType={selectUserType}
              />
            </div>

              <div className="row">
                <EditUserForm 
                userForm={userForm}
                userForminputChange={userForminputChange}
                editUserFunction={editUserFunction}
                history={history}
                />
              </div>
            </div>
        </div>
  
        <div className="col-12 col-lg-4">
          <NavLink to="/gestion/usuarios">
            <div className="call-to-action-link">
              <h6 id="call-to-action-user-list" className="title" >Ver la lista de usuarios</h6>
              <img src={arrow} alt=""/>
            </div>
          </NavLink>
        </div>
      </div> 
    )
    
  }
}
// Component thet regirsters users
export const RegisterUser = (props) => {

  const {gestion, selectUserType, userForm, userForminputChange, createUserFunction, history} = props;

  if (!gestion.userType ||  !gestion.govType ) {
   return null;
  }else {
    return (  

      <div className="row">
        <div className="register-container col-12 col-lg-8">
          <div>
            <div className="register-img-container">
              <img src={trabajando} alt=""/>
              <h6>Estamos trabajando para ti</h6>
            </div>
  
            <div className="row">
              
              <SelectUserTypeInput
              selectUserType={selectUserType}
              userForm={userForm}
              gestion={gestion}
              />

              <SelectGovernType 
              userForm={userForm}
              gestion={gestion}
              selectUserType={selectUserType}
              />
              
              <SelectInsuranceType
              userForm={userForm}
              gestion={gestion}
              selectUserType={selectUserType}
              />
            </div>

              <div className="row">
                <CreateUserForm 
                userForm={userForm}
                userForminputChange={userForminputChange}
                createUserFunction={createUserFunction}
                history={history}
                />
              </div>
            </div>
        </div>
  
        <div className="col-12 col-lg-4">
          <NavLink to="/gestion/usuarios">
            <div className="call-to-action-link">
              <h6 id="call-to-action-user-list" className="title" >Ver la lista de usuarios</h6>
              <img src={arrow} alt=""/>
            </div>
          </NavLink>
        </div>
      </div> 
    )
 
    
  }

}