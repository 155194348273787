import http from "./httpServices";

export async function getInsurranceList(sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/insurance`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}
