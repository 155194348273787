import http from "./httpServices";


// Function that POST data form for creating users
export async function createBond(datos, sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/bond`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  

 try {
    const result = await http.post(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


// Function that PUTs data form for editing bonds
export async function updateBondById(datos, sessionToken, bondId) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/bond/${bondId}`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  

 try {
    const result = await http.put(url, datos ,config);
    return result;
  } catch (error) {
    return error.response;
  }
}



//Function that gets the bond list
export async function getMyBondsList(sessionToken,userId) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${userId}/bond`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}

//Function that gets the bond list
export async function getAllBondsList(sessionToken) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/bond`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}


//Function that gets the bond bi its name
export async function getBondByNumber(sessionToken,bondNumber) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/bond/bond-number/${bondNumber}`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getPublicBondsList() {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/bond`;

  const config = {
    headers: {     
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}

export async function getMyBondsListGov(sessionToken,userId) {

  let url = process.env.REACT_APP_API_URL;
  url = url + `/${userId}/bond-by-entity`;

  const config = {
    headers: {     
       'Authorization': `${sessionToken}`, 
       'Content-Type': 'application/json', 
    }
  }  
  
  try {
    const result = await http.get(url,config);
    return result;
  } catch (error) {
    return error.response;
  }
}