import React from 'react';
import {Switch, Route, NavLink, Redirect} from "react-router-dom";
import {UsersTable, BondsTable, GovBondsTable} from "../../common/Tables/Tables";
import {BondComment, BondCommentList} from "../../common/BondComment/BondComment";
import {PasswordChangeForm} from "../../common/Forms/Forms";
import {MenuHeader} from "./MenuHeader";
import {Sidebar} from "../../common/Sidebar/Sidebar";
import {RegisterUser,EditUser} from "./Usuarios/Usuarios";
import auth from "../../../services/authService";
import ReactPaginate from 'react-paginate';
import arrow from  "../../../images/arrow.png";
import mas from "../../../images/mas.png";
import "./Menu.scss";
import empty from 'is-empty';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';
import { Report } from '../../Report/Report';



// Button that creates new users 
export const CreateNewUserButton = () => {

    return (
      <div className="button login-button">
        <h6 id="create-new-user-button">Crear nueva</h6> <img src={mas} alt={mas} />
      </div>
    )
}


// User list component 
export const UserList = (userList , handleUserPageClick, query, searchUserBox, loadUserDataForEdition) => {

  /*************************************************SEARCHBOX************************************************/
  const handleInputChange = (event) => {
    if(!empty(event)){
      query = event.target.value
      searchUserBox(query);
    }
  }
  /*************************************************SEARCHBOX************************************************/

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="userlist-search-container">
            <input 
              autoComplete="off" 
              className="input"
              type="text"
              name="userFilter"
              placeholder="Buscar"
              onChange={handleInputChange}
            />
        </div>

        </div>
        <div className="call-to-action-back col-12 col-lg-4">
          <NavLink to="/gestion/registro">
            <div className="call-to-action-link">
              <h6 id="call-to-action-user-list" className="title" >Volver</h6>
              <img src={arrow} alt=""/>
            </div>
          </NavLink>
        </div>
      </div>

      {!empty(userList) ? 
        <div className="align-table-left">
          <UsersTable
            users={userList.data}
            loadUserDataForEdition={loadUserDataForEdition}
          />

          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={userList.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handleUserPageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      : <div className="call-to-action-link"> 
          <div className="empty-lists">No hay usuarios registrados</div>
        </div>
      }
    </div>
  )
}

// My Bonds list component 
export const BondList = (
  bondsList, 
  handleBondPageClick, 
  query, 
  searchBondsBox, 
  loadBondDataForEdition,
  comments, 
  closeCommentListModal, 
  openCommentListModal,
  audit,
  openAuditModal,
  closeAuditModal,
  auditTextAreaChange,
  createAuditFunction,
  openBase64File,
  loadList
) => {

  /*************************************************SEARCHBOX************************************************/
  const handleInputBondsChange = (event) => {
    if(!empty(event)){
      query = event.target.value
      // gov to filter by government
      searchBondsBox(query, "Aseguradora");
    }
  }
  /*************************************************SEARCHBOX************************************************/

  return (
    <div className="container">

      <LoadSpinner blocked={loadList} text="Buscando fianzas" color="grey"/>

      <BondComment
        audit={audit}
        closeAuditModal={closeAuditModal} 
        auditTextAreaChange={auditTextAreaChange}
        createAuditFunction={createAuditFunction}
        openBase64File={openBase64File}
      />

      <BondCommentList
      comments={comments}
      closeCommentListModal={closeCommentListModal} 
      />

      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="userlist-search-container">
            <input 
              autoComplete="off" 
              className="input"
              type="text"
              name="bondFilter"
              placeholder="Buscar"
              onChange={handleInputBondsChange}
            />
        </div>

        </div>
      </div>

      {!empty(bondsList.data) ?
        <div className="align-table-left">
          <BondsTable
            bonds={bondsList.data}
            loadBondDataForEdition={loadBondDataForEdition}
            openCommentListModal={openCommentListModal}
            openAuditModal={openAuditModal}
          />

          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={bondsList.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handleBondPageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      : <div className="call-to-action-link"> 
          {/* <div className="empty-lists">No hay fianzas registradas</div> */}
        </div>
      }
    </div>
  )
}


// Gov Bonds list component 
export const GovBondList = (
  bondsList, 
  handleBondPageClick, 
  query, 
  searchBondsBox, 
  openAuditModal,
  closeAuditModal, 
  audit, 
  auditTextAreaChange, 
  createAuditFunction, 
  openBase64File, 
  comments, 
  closeCommentListModal,
  openCommentListModal,
  loadList) => {

  /*************************************************SEARCHBOX************************************************/
  const handleInputGovBondsChange = (event) => {
    if(!empty(event)){
      query = event.target.value
      // gov to filter by government
      searchBondsBox(query, "Gobierno");
    }
  }
  /*************************************************SEARCHBOX************************************************/
  return (
    <div className="container">

      
      <LoadSpinner blocked={loadList} text="Buscando fianzas" color="grey"/>

      <BondComment
      audit={audit}
      closeAuditModal={closeAuditModal} 
      auditTextAreaChange={auditTextAreaChange}
      createAuditFunction={createAuditFunction}
      openBase64File={openBase64File}
      />

      <BondCommentList
      comments={comments}
      closeCommentListModal={closeCommentListModal} 
      />

      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="userlist-search-container">
            <input 
              autoComplete="off" 
              className="input"
              type="text"
              name="bondGovFilter"
              placeholder="Buscar"
              onChange={handleInputGovBondsChange}
            />
        </div>

        </div>
      </div>

      {!empty(bondsList.data) ?
        <div className="align-table-left">
          <GovBondsTable
            bonds={bondsList.data}
            openAuditModal={openAuditModal}
            openCommentListModal={openCommentListModal}
          />

          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={bondsList.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handleBondPageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      : 
        <div className="call-to-action-link"> 
          {/* <div className="empty-lists">No hay fianzas registradas</div> */}
        </div>
      }
    </div>
  )
}


// Button that saves the register
export const savePasswordButton = (updatePasswordFunction,history,passwordUpdateForm) => {

  let {oldPassword, newPassword, confirmNewPassword} = passwordUpdateForm.data;

  if (empty(oldPassword) || empty(newPassword) || empty(confirmNewPassword)) {
    return (
      <div className="button login-button blocked-button">
        <h6>Guardar</h6>
      </div>
    )
  } else {
    return (
      <div onClick={() => updatePasswordFunction(history)} className="button login-button">
        <h6>Guardar</h6>
      </div>
    )
  }
}

// PAge that hold s the change password menu
export const ChangePasswordPage = (props) => {

  const {userSession,restoreAllForms, logoutService, passwordForminputChange, setPasswordShownValue,updatePasswordFunction,history, passwordUpdateForm,passwordShown} = props;

  return (
    <div className="menu-container">
       <Sidebar
          userSession={userSession}
          restoreAllForms={restoreAllForms}
        />
      <div className="menu-dashboard container">
      <MenuHeader
        title={"Cambio de clave"}
        userSession={userSession}
        logoutService={logoutService}
        props={props}
      />
      <PasswordChangeForm
      passwordForminputChange={passwordForminputChange} 
      passwordShown={passwordShown} 
      setPasswordShownValue={setPasswordShownValue} 
      updatePasswordFunction={updatePasswordFunction} 
      history={history}
      passwordUpdateForm={passwordUpdateForm}
      />
      </div>
    </div>
  )
}

// Main menu component
export const Menu = (props) => {

    const {userList, handleUserPageClick, handleBondPageClick, userSession, logoutService, gestion, selectUserType, userForm, userForminputChange, 
      createUserFunction, searchUserBox, searchBondsBox, bondsList, restoreAllForms, openAuditModal, closeAuditModal, audit, auditTextAreaChange,
      createAuditFunction, openBase64File, loadBondDataForEdition, loadUserDataForEdition, editUserFunction, comments, closeCommentListModal, 
      openCommentListModal, loadList, handleReportStartDateChange, handleReportEndDateChange, selectReportInsurance, selectReportGovEntity, 
      reportForm, reportFormInputChange, selectReportGuaranteeType, reportFunction} = props;
  
  let {query} = props;

  return (
    <div className="menu-container">

      <Sidebar
      userSession={userSession}
      restoreAllForms={restoreAllForms}
      />

      <Switch>
        <Route
          path="/gestion/reporte"
          render={() => {
            return (
              <React.Fragment>
                <div className="menu-dashboard container">
                  <MenuHeader
                    title={"Reporte"}
                    userSession={userSession}
                    logoutService={logoutService}
                    props={props}
                  />
                  <Report 
                    gestion={gestion}
                    handleReportStartDateChange={handleReportStartDateChange}
                    handleReportEndDateChange={handleReportEndDateChange}
                    selectReportInsurance={selectReportInsurance}
                    selectReportGovEntity={selectReportGovEntity}
                    selectReportGuaranteeType={selectReportGuaranteeType}
                    reportForm={reportForm}
                    reportFormInputChange={reportFormInputChange}
                    reportFunction={reportFunction}
                    userSession={userSession}
                  />
                </div>
              </React.Fragment>
            )
          }}
        >
        </Route>
       <Route
         path="/gestion/registro"
         render={() => {
           if (auth.getUserType() === "Administrador" ){
            return (
              <React.Fragment>
               <div className="menu-dashboard container">
                 <MenuHeader
                 title={"Gestión de usuarios"}
                 userSession={userSession}
                 logoutService={logoutService}
                 props={props}
                 />
                 <RegisterUser
                 gestion={gestion}
                 selectUserType={selectUserType}
                 userForm={userForm}
                 userForminputChange={userForminputChange}
                 createUserFunction={createUserFunction}
                 history={props.history}
                 />
               </div>
              </React.Fragment>
            )
           }else {
            return(
              <Redirect to="/login"/>
            )
           }
         }}
         />
         <Route
         path="/gestion/edicion-usuario"
         render={() => {
           if (auth.getUserType() === "Administrador" ) {
            return (
              <React.Fragment>
               <div className="menu-dashboard container">
                 <MenuHeader
                 title={"Edición de usuarios"}
                 userSession={userSession}
                 logoutService={logoutService}
                 props={props}
                 />
                 <EditUser
                 gestion={gestion}
                 selectUserType={selectUserType}
                 userForm={userForm}
                 userForminputChange={userForminputChange}
                 createUserFunction={createUserFunction}
                 history={props.history}
                 editUserFunction={editUserFunction}
                 />
               </div>
              </React.Fragment>
            )
           }else {
            return(
              <Redirect to="/login"/>
            )
           }
         }}
         />
        <Route
         path="/gestion/usuarios"
         render={() => {
           if (auth.getUserType() === "Administrador") {
            return (
              <React.Fragment>
               <div className="menu-dashboard container">
               <MenuHeader
                 title={"Lista de usuarios"}
                 userSession={userSession}
                 logoutService={logoutService}
                 props={props}
               />
                   {UserList(userList, handleUserPageClick, query, searchUserBox, loadUserDataForEdition)}
               </div>
              </React.Fragment>
            )
           } else {
            return(
              <Redirect to="/login"/>
            )
           }
         }}
         />
        <Route
          path="/gestion/fianzas"
          render={() => {
            if (auth.getUserType() === "Gobierno") {
              return (
                <React.Fragment>
                  <div className="menu-dashboard container">
                    <MenuHeader
                      title={"Lista de fianzas"}
                      userSession={userSession}
                      logoutService={logoutService}
                      props={props}
                    />
                    {
                      GovBondList(bondsList, 
                                  handleBondPageClick, 
                                  query, 
                                  searchBondsBox, 
                                  openAuditModal, 
                                  closeAuditModal, 
                                  audit, 
                                  auditTextAreaChange, 
                                  createAuditFunction, 
                                  openBase64File, 
                                  comments, 
                                  closeCommentListModal, 
                                  openCommentListModal, 
                                  loadList)}
                  </div>
                </React.Fragment>
              )
            } else {
              return(
                <Redirect to="/login"/>
              )  
            }
          }}
        />

        <Route
          path="/gestion/consulta-fianza"
          render={() => {
            if (auth.getUserType() === "Aseguradora" ) {
              return (
                <React.Fragment>
                  <div className="menu-dashboard container">
                    <MenuHeader
                      title={"Lista de fianzas"}
                      userSession={userSession}
                      logoutService={logoutService}
                      props={props}
                    />
                    {BondList(
                      bondsList, 
                      handleBondPageClick, 
                      query, 
                      searchBondsBox, 
                      loadBondDataForEdition, 
                      comments, 
                      closeCommentListModal, 
                      openCommentListModal, 
                      audit,
                      openAuditModal,
                      closeAuditModal,
                      auditTextAreaChange,
                      createAuditFunction,
                      openBase64File,
                      loadList,
                    )}
                  </div>
                </React.Fragment>
              )
            }else {
              return(
                <Redirect to="/login"/>
              )
            }
          }}
        />
        <Route>
          <Redirect props={props} to="/consulta" />
        </Route>
      </Switch>
    </div>
  );
};